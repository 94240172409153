import react from 'react';
import ErrorPage from './containers/SignUp/errorpage';
import Footer from './containers/Footer';

function pageError(){
        return(
            <div className="Mnlexpress">
                <ErrorPage />
                <Footer />
            </div>
        )
}
export default pageError;