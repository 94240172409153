import NavbarLink from "./NavLink";

function NavbarLinks({ links, ...props }) {
    return (
        <>
            {links.map(e => <NavbarLink className="Nav-links" key={e.label} label={e.label} link={e.link} onClick={e.onClick}  styles={{label: { fontSize: 16, color:'#ffffff !important' },}}/>)}
        </>
    );
}

export default NavbarLinks;