import {Box, Flex, Stack, Title, Text, Image} from '@mantine/core';
import { ForeColorYellow, ForeColorBlack } from "../../lib/constants";

function AboutUs(){
    // const aboutUsImg = "img/aboutUsImg.png"
    const aboutUsImg = "/img/aboutMnlCargo.png"
    return(
        // <Box id="aboutUs" bg='white' w='100vw' h="100vh" mt={{base: '100%',xs: '100%', sm: '900px', md: '900px', lg: '0%'}}>
        <Box id="aboutUs" bg='white' w='100%' h="100%">
            <Flex h='100%' direction={{ base: 'column-reverse', md: 'column-reverse', xs: 'column-reverse', lg: 'row' }}  wrap="no-wrap">
                <Image src={process.env.PUBLIC_URL + aboutUsImg} id="chooseUs-art" w={{ lg: '30vw', md: '100%', base: '100%' }} maw='100%' miw='30%' style={{objectFit:'cover',  objectPosition: '10% 90%'}}/>
                <Stack id="chooseUs-contents" bg={ForeColorYellow} align="flex-start" w={{ md: "100%", sm: '100%', lg: '100%' }} gap='xl'>
                    <Flex className='Au-content' align={{base:'center', md:'start', lg:'start'}} justify='center' ta={{base: 'center'}} direction="column" px="15%" py="15%" h='100%' gap={25}>
                        
                        <Title className='Au-title' order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 600, fontSize:36 }} align="start">About Us</Title>
                        <Text  className='Au-text' style={{color:ForeColorBlack, fontSize:"1.125 rem" }} ta={{ base:'center', md:'left', lg:'left' }}>For over 35 years, MNL Xpress has been the trusted partner for OFWs in Canada, connecting hearts and homes across the miles.</Text>
                        
                        <Text  className='Au-text' style={{color:ForeColorBlack, fontSize:"1.125 rem" }} ta={{ base:'center', md:'left', lg:'left' }}>We're your one-stop shop for all your payment and remittance needs, allowing you to focus on what truly matters - connecting with your loved ones.</Text>
                        
                        <Text  className='Au-text' style={{color:ForeColorBlack, fontSize:"1.125 rem" }} ta={{ base:'center', md:'left', lg:'left' }}>With competitive rates, flexible options, and a dedicated customer support team, every remittance you send to the Philippines is handled securely, and with care.</Text>

                   </Flex>
                </Stack>
      
            </Flex> 
        </Box>
    );
}

export default AboutUs;