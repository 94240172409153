import { NavLink, Anchor } from "@mantine/core";

function NavbarLink({ label, link, onClick }) {
    return (
        <div>
        <NavLink href={link} label={label} styles={{
                label: { fontSize: 16, marginLeft: 0 },
                root: { width: 'unset' }
            }} onClick={onClick} />
        
        </div>
        
    );
}

export default NavbarLink;