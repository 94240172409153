import React, { useEffect, useState } from 'react';
import Update from './containers/SignUp/Update';
import Footer from './containers/Footer';
import {useParams, useNavigate} from 'react-router-dom';
import { httpClient } from './containers/SignUp/api/base/httpclient';
import { links_suffix } from './containers/SignUp/constant/links-suffix';


export default function UpdateAccount() {
    const {hash_code} = useParams();
    const navigate = useNavigate();
    const [hashValid, setHashValid] = useState(0);

    useEffect(()=>{ 
        fetchUserDetails();
      },[]);
      

      const fetchUserDetails = async () => {

        try{
  
            const userDetails = await httpClient.post(links_suffix.GetUserDetails, {
              hash_code: hash_code,
            });
  
            if(userDetails?.data?.status == 1){
              setHashValid(1);
            }else{
                navigate('/');
            }
          } catch (error) {
            navigate('/');
          }
  
      }

    return (
        <div className="Mnlexpress">
            {hashValid == 1 && (
                <div>
                    <Update />
                    <Footer />
                </div>
            )}
            
        </div>
    );
}