import axios from 'axios';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
    
    'Content-Type': 'multipart/form-data',
    "Api-Key": process.env.REACT_APP_API_KEY,
    Accept: 'application/json',
    // eslint-disable-next-line camelcase, object-shorthand
    // sess_id: sess_id,
  },
});
httpClient.interceptors.response.use(
  response => response,
  error => {
      console.error('Error response:', error.response);
      return Promise.reject(error);
  }
);
