import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppRouter from "./router";
import RouteProvider from "./routes/RouteProvider";
import '@mantine/dates/styles.css';

import reportWebVitals from './reportWebVitals';
import Mnlexpress from './mnlexpress';
import MetaPixel from './components/utlis/MetaPixel';
import { MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import { BrowserRouter } from "react-router-dom";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-DXQ8703NPG');

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
    fontFamily: "SalmondRegular",
    breakpoints: {
        lg: '82em', //1312px
    },
})

root.render(
    <React.StrictMode>
        {/* <BrowserRouter> */}
        <MetaPixel />
        <MantineProvider theme = { theme } >
        <RouteProvider />
        </MantineProvider>
        {/* </BrowserRouter> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();