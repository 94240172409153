import { Box, Text } from "@mantine/core";
import { ForeColorYellow } from "../lib/constants";

function MxOption({label, ...props}) {
    return (
        <Box px={20} py={10} bg={ForeColorYellow} {...props}>
            <Text  className='bold' style={{ fontSize:'18px',fontWeight: 600 }}>{label}</Text>
        </Box>
    );
}

export default MxOption;