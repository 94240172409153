import Home from './containers/Home';
import AboutUs from './containers/AboutUs';
import Xrate from './containers/Xrate';
import Remittance from './containers/Remittance';
import ChooseTheRemittance from './containers/ChooseTheRemittance';
import OtherServices from './containers/OtherServices';
import CustomerTestimonials from './containers/CustomerTestimonials';
import Footer from './containers/Footer';

function Mnlexpress(){
    return(
        <div className="Mnlexpress">
            <Home />
            <Xrate />
            <AboutUs />
            <Remittance />
            <ChooseTheRemittance />
            <OtherServices />
            <CustomerTestimonials />
            <Footer />
        </div>
    );
}

export default Mnlexpress;