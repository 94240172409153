import MnlExpress from "../mnlexpress";
import SignUp from "../signup";
import CreateAccount from "../createaccount";
import UpdateAccount from "../update-account";
import SuccessPage from "../success";
import ErrorPage from "../pageerror";
import PrivacyPolicy from "../privacy";
import TermsCondition from "../terms";
const routes = [
  {
    element: <MnlExpress />,
    path:"/"
  },
  {
    element: <SignUp />,
    path:"/sign-up"
  },
  {
    element: <CreateAccount />,
    path:"/create-account"
  },
  {
    element: <UpdateAccount />,
    path:"/verify/:hash_code"
  },
  {
    element: <SuccessPage />,
    path:"/success"
  },
  {
    path: "*", // Catch-all route for undefined paths
    element: <ErrorPage />, // Redirect to ErrorPage for non-existent routes
  },
  {
    element: <PrivacyPolicy />,
    path:"/privacy"
  },
  {
    element: <TermsCondition />,
    path:"/terms"
  }

];

export default routes;
