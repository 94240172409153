import React from 'react';
import SuccessPage from './containers/SignUp/SuccessResult';

import Footer from './containers/Footer';



function success(){
    return (
        <div className="Mnlexpress">
            <SuccessPage />
            <Footer />
        </div>

    );
}

export default success;

