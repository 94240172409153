import { Box, Burger, Text, Drawer, Flex, Button } from "@mantine/core";
import { useState } from "react";
import NavbarLinks from "./NavLinksMobile";
import { useWindowScroll } from "@mantine/hooks";
import { primaryColor,primaryBgColor } from "../../lib/constants";
import * as Icons from 'lucide-react';
import { useNavigate } from 'react-router-dom';
function NavMobile() {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [scroll, scrollTo] = useWindowScroll();
    const navigate = useNavigate();

    const navigateToSignup = () => {
        navigate('/sign-up');
    }


    function getOffset(id) {
        return document.getElementById(id).offsetTop;
    }

    function scrollToXrate() {
        const xRate = getOffset('Xrate');
        scrollTo({ y: xRate-10 });
        closeDrawer();
    }
    function scrollToAboutUs() {
        const aboutUs = getOffset('aboutUs');
        scrollTo({ y: aboutUs-70 });
        closeDrawer();
    }
    function scrollToRemittance() {
        const remittance = getOffset('remittance');
        scrollTo({ y: remittance });
        closeDrawer();
    }
    function scrollToOtherServices() {
        const otherServices = getOffset('otherServices');
        scrollTo({ y: otherServices - 70 });
        closeDrawer();
    }
    function scrollToCustomerTestimonials() {
        const customerTestimonials = getOffset('customerTestimonials');
        scrollTo({ y: customerTestimonials + 250});
        closeDrawer();
    }

    function closeDrawer() {
        setDrawerOpen(false);
    }

    return (
        <Box component="div" hiddenFrom="lg">
            <Drawer position="right" size='sm' opened={drawerOpen} onClose={closeDrawer} title='MENU' overlayProps={{ blur: 2 }}>
            <Flex direction='column' mt='20' align='left' justify='left'>
            <NavbarLinks links={[
                {
                    label: "Today's Rates",
                    onClick: scrollToXrate
                },
                {
                    label: 'About Us',
                    onClick: scrollToAboutUs
                },
                {
                    label: 'Remittance',
                    onClick: scrollToRemittance
                },
                {
                    label: 'Other Services',
                    onClick: scrollToOtherServices
                },
                {
                    label: 'Customer Testimonials',
                    onClick: scrollToCustomerTestimonials
                },
            ]} />
            </Flex>
           
            <Box className='home-contact' pos='absolute' bottom='0'  ml={12}>
            <Button color="#ffcc05"
                className="mtbtn"
                h = {{xs:50, md:60}}
                w= {{xs:200, md:200}}
                px={{xs: 24, md:24}}
                style={{
                    
                    height: "60px",
                    width: "250px",
                    fontSize: "24px",
                    fontFamily: "Salmond",
                    borderRadius: "50px",
                    color: 'black',
                    border: '2px solid #ffcc05',
                    marginButtom: '50px',
                    

                }} 
                onClick={navigateToSignup}
                >
                            
                <Text style={{fontSize: '16px'}}>Create an Account</Text>
                <Icons.ArrowRight style={{marginBottom: '3px', marginLeft: '30px'}} />
                </Button>
                <Text order={4} style={{ fontWeight: 500, color: primaryColor, fontSize:12,marginTop:'50px' }}>customerservice@csg.manilaxpress.com</Text>
                <Text order={4} style={{ fontWeight: 500, color: primaryColor, fontSize:12 }}>(236) 200-5859</Text>
            </Box>
            </Drawer>
            {/* Burger ICon */}
            <Flex align='center' gap={2} style={{ position: 'absolute',  top: '10%', right: '10%' }} >
                <Text fz={16}>Menu</Text>
                <Button variant="transparent" w='100%' px={0} py={0} onClick={() => setDrawerOpen(true)}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 19.5C6.82843 19.5 7.5 18.8284 7.5 18C7.5 17.1716 6.82843 16.5 6 16.5C5.17157 16.5 4.5 17.1716 4.5 18C4.5 18.8284 5.17157 19.5 6 19.5Z" fill="white"/>
                    <path d="M30.09 16.5H11.91C11.1313 16.5 10.5 17.1313 10.5 17.91V18.09C10.5 18.8687 11.1313 19.5 11.91 19.5H30.09C30.8687 19.5 31.5 18.8687 31.5 18.09V17.91C31.5 17.1313 30.8687 16.5 30.09 16.5Z" fill="white"/>
                    <path d="M30.09 24H5.91C5.13128 24 4.5 24.6313 4.5 25.41V25.59C4.5 26.3687 5.13128 27 5.91 27H30.09C30.8687 27 31.5 26.3687 31.5 25.59V25.41C31.5 24.6313 30.8687 24 30.09 24Z" fill="white"/>
                    <path d="M30.09 9H5.91C5.13128 9 4.5 9.63128 4.5 10.41V10.59C4.5 11.3687 5.13128 12 5.91 12H30.09C30.8687 12 31.5 11.3687 31.5 10.59V10.41C31.5 9.63128 30.8687 9 30.09 9Z" fill="white"/>
                    </svg>
                </Button>
                {/* <Burger aria-label="Mobile navigation" component='button' onClick={() => setDrawerOpen(true)} /> */}
            </Flex>
            {/* Burger ICon */}
        </Box>
    );
}

export default NavMobile;