import React, { useState, useEffect } from 'react';
import {Box, Flex, Title, Text, Image, Group, BackgroundImage} from '@mantine/core';
import TestimonyCarousel from './testimonyCarousel';
import { ForeColorPurple } from '../../lib/constants';

function CustomerTestimony()
{
    const [activeindex, setActiveIndex] = useState(0);
    const customerTestimonials = "/img/customerTestimonials.png";

    const testimonies = [
        {
          "message": "I'm so glad I found MNL Xpress! My parents in the Philippines finally get their money on time, and I don't have to deal with long lines or confusing forms. Plus, their customer service is always helpful.",
          "name": "Josephine Villegas",
          "address": "Ontario"
        }
      ]
    ;

    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex(activeindex => (activeindex + 1) % testimonies.length);
        }, 5000); 
    
        return () => clearInterval(interval);
      }, [testimonies.length]);

    const renderIndicators = () => {
        return Array.from({ length:testimonies.length }, (_, index) => (
          <div
            
            key={index}
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: index === activeindex ? ForeColorPurple : '#ccc',
              margin: '10px 5px',
              cursor: 'pointer',
            }}
            onClick={() => handleSlideChange(index)}
          />
        ));
    };

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    return(
        <Flex direction="column" visibleFrom='lg'> 
            
            <Flex w="100%" mih="1100px" direction='row'>
                <Flex direction="column" className='Ct-title-container' style={{position:"absolute", right:"50%"}} mr='300px'>

                    <Flex justify='center' align='center'>
                      <Title className='Ct-title' style={{transform: 'rotate(-90deg)', marginTop:"400px"}}>
                          Customer Testimonials
                      </Title>
                    </ Flex>

                    <Flex className='Ct-carousel-bullets' style={{marginTop:"400px", marginBottom:"10%"}} align='center' justify='center'>
                        {renderIndicators()}
                    </Flex>
                    
                    
                </Flex>
           
                <Box>
                    <Flex mt={120} className='CT-image-container' style={{position:"absolute", right:"50%"}}>
                    
                    <TestimonyCarousel testimonies={testimonies} activeIndex={activeindex}/>
                        <Image className='CT-image'
                            src={process.env.PUBLIC_URL + customerTestimonials}
                            style={{height: 'auto', position:"absolute", top: '0%', left: '0%' }}
                            w='100%'
                            />
                    </Flex>
                </Box>
            </Flex> 
           
            <Box className='customer-text-box' pb="20px" pl="10px" mt='-150px' pr='10px'>
                <Text style={{fontWeight:600,fontSize:48, color:ForeColorPurple}}>Ready to send love back home?</Text>
                <Flex direction="row">
                    <Text style={{fontSize:18, color:"#323232"}}>Go to the nearest </Text>
                    <Text  className='bold' px="10px" style={{fontSize:18, fontWeight:"800", color:"#323232"}}> MNL Xpress </Text> 
                    <Text  style={{fontSize:18, color:"#323232"}}>branch today!</Text>
                </Flex>
            </Box>
        </Flex>
    );


}

export default CustomerTestimony;