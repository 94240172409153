import React, { useState, useEffect } from 'react';
import { Carousel } from '@mantine/carousel';
import { Image, Box } from '@mantine/core';
import { ForeColorYellow } from '../../lib/constants';


function OtherServicesCarousel({activeIndex}) {

    

    const [activeindex, setActiveIndex] = useState(0);

    const CarouselImages = ["/img/billsPayment.jpg", "/img/cashCardLoading.jpg"];

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
       setActiveIndex(activeIndex);
    }, [activeIndex]);


    return (
        <>
            <Carousel align="start" withIndicators height={200} activendex={activeindex} onSlideChange={handleSlideChange} controls={true} w='100%'>
                {CarouselImages.map((image, index) => (
                    <Carousel.Slide key={index} style={{ display: index === activeindex ? 'block' : 'none' }}>
                            <Image src={process.env.PUBLIC_URL + image} w={{base:'100%', sm: '100%', md:'100%', lg:'50vw'}} h={{base:'50vh', sm: '80vh', md:'80vh', lg: '80vh'}} alt={`Slide ${index}`} style={{ objectFit: 'cover', objectPosition: '60% 40%' }} />
                    </Carousel.Slide>
                ))}
            </Carousel>
        </>
    );
}

export default OtherServicesCarousel;