import React, { useState }  from 'react';
import * as Mantine from '@mantine/core';
import { useEffect } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import { MoveRight } from 'lucide-react';
import * as Icons from 'lucide-react';
function SuccessResult() {
  const navigate = useNavigate();
  const [isSuccess, setISuccess] = useState(0);
    useEffect(() => {
        const is_success = localStorage.getItem('is_success');
        if (is_success === null) {
            navigate('/sign-up');
        }else{
          localStorage.removeItem('captcharef');
          localStorage.removeItem('termsref');
          setISuccess(1);
        }
    }, []);
    const theme  = Mantine.createTheme({
        fontFamily: 'Salmond, SalmondRegular, sans-serif',
        fontSizes: {
            xs: Mantine.rem(10),
            sm: Mantine.rem(11),
            md: Mantine.rem(14),
            lg: Mantine.rem(16),
            xl: Mantine.rem(20),
        },

    });
    useEffect(() => {
        // Clear the values once validation is successful
        if(isSuccess == 1){
          localStorage.removeItem('is_success');
        }
        
    }, [isSuccess]);
     const bringmetoHome = () => {
        navigate('/');
     }
    return (
      <div style={{ margin: '0', padding: '0', fontFamily: 'Salmond', backgroundColor: '#b8d5e0', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        <div className='divtext'>
          <h1>You're on your way!</h1>
          <p style={{marginButtom: '30px'}}>
            Your application has been submitted for review and processing.<br /><br />
            We will email you to schedule a verification call within 48 hours. For any inquiries, you may contact us at (236) 200-5859 or email
            <a href="mailto:customerservice@csg.manilaxpress.com"> customerservice@csg.manilaxpress.com</a>.
          </p>
          <Mantine.Button color="#FFCC05"
            h = {{xs:50, md:60}}
            w= {{xs:200, md:200}}
            px={{xs: 24, md:24}}
            style={{
                
              height: "60px",
              width: "300px",
              fontSize: "24px",
              fontFamily: "Salmond",
              borderRadius: "50px",
              color: 'black',
              border: '2px solid #ffcc05',
              marginTop: "50px"

            }}
            onClick={bringmetoHome}
            >
                            
            <Mantine.Text style={{fontSize: '20px'}}>Back to Home </Mantine.Text>
            <Icons.ArrowRight style={{marginBottom: '3px', marginLeft: '30px'}} />
          </Mantine.Button>
        </div>
        <div>
          <img className='femalemodel' src="./img/femalemodel.png" alt="Happy woman" />
        </div>
      </div>

      //   <div style={{ margin: '0', padding: '0', fontFamily: 'Salmond', backgroundColor: '#b8d5e0', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
      //     <div className='divtext' style={{ maxWidth: '700px', padding: '20px', textAlign: 'left', marginLeft:'100px' }}>
      //         <h1 style={{ fontSize: '32px', color: '#333', marginBottom: '10px' }}>You're on your way!</h1>
      //         <p style={{ fontSize: '18px', color: '#666', lineHeight: '1.6', marginBottom: '30px' }}>
      //           Your application has been submitted for review and processing.<br /><br />
      //           We will email you to schedule a verification call within 48 hours. For any inquiries, you may contact us at (236) 200-5859 or email
      //           <a href="mailto:customerservice@csg.manilaxpress.com" style={{ color: '#00AEEF', textDecoration: 'none' }}> customerservice@csg.manilaxpress.com</a>.
      //         </p>
      //         <a href="/" style={{ display: 'inline-block', padding: '15px 30px', backgroundColor: '#F1C40F', color: '#333', textDecoration: 'none', fontSize: '16px', borderRadius: '30px', fontWeight: 'bold', color: 'black',marginTop:'20px' }}>
      //           Back to Home
      //           <span style={{ marginLeft: '10px' }}>→</span>
      //         </a>
      //     </div>
      //     <div >
      //       <img className='femalemodel'  src="./img/femalemodel.png"  alt="Happy woman" style={{ width: '100%', borderRadius: '10px' }} />
      //     </div>
      // </div>
        // <div style={{
        //     backgroundColor: 'rgb(185,213,224)',
        //     minHeight: '80vh',
        //     maxHeight: '80vh',
        //     padding: '40px',
        // }}>
           
        // <Mantine.Grid>
        //     <Mantine.Grid.Col span={6} style={{
        //         marginTop: '60px',
        //     }}>
        //         <Mantine.Title className='successTitle'>You're on your way!</Mantine.Title>
        //         <Mantine.Text >Your application has been submitted for review and processing.</Mantine.Text><br />
        //         <Mantine.Text>We will email you to schedule a verification call within 48 hours. For any inquiries, you may contact us at (236) 200-5859 or email customerservice@csg.manilaxpress.com.</Mantine.Text>
        //         <Mantine.Text>Thank you for choosing Manila Express!</Mantine.Text>
        //     </Mantine.Grid.Col>
        //     <Mantine.Grid.Col span={6} style={{
        //         marginTop: '60px',
        //     }}>
        //         <img 
        //             src="./img/femalemodel.png" 
        //             style={{
        //                 position: 'block',
        //                 top: '140px', 
        //                 right:  0, 
        //                 width: '100%', 
        //                 height: '100%', 
        //                 borderRadius: '8px', 
        //                 objectFit: 'cover', // Cover the container
        //                 transform: 'translateX(5%)', // Crop right part of the image
        //                 overflow: 'hidden'
        //               }} 
        //         />
        //     </Mantine.Grid.Col>
            
        // </Mantine.Grid>

        // </div>
        
    );
}
export default SuccessResult;