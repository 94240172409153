// PrivacyPolicy.js
import React, { useState } from 'react';


const termscondition = () => {
 

  return (
    <div className="privacy-policy">
      <h1 className='myt'>Terms & Conditions</h1>
      <p>Please read these terms and conditions carefully before using Our Service.</p>
        <h2>Interpretation and Definitions</h2>

        <h3>Interpretation</h3>
        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

        <h3>Definitions</h3> <br/>
        <ul>
            For the purposes of these Terms and Conditions:
            <ul>
                    <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                    <li>Account means a unique account created for You to access our Service or parts of our Service.</li>
                    <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers Manila Express Inc., 8080 Park Road, Richmond, B.C. V6Y 1T1, Canada.</li>
                    <li>Content refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
                    <li>Country refers to: Canada</li>
                    <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                    <li>Order refers to the remittance transaction.</li>
                    <li>Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
                    <li>Third-Party Services means any services or content (including data, information, applications and other products services) provided by a third-party that may be displayed, included or made available.</li>
                    <li>Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                    <li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service/Application, as applicable.</li>
            </ul>
        </ul>

        <h3>Acknowledgement </h3>
        <p>
        These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service. <br/><br/>
        Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.<br/><br/>
        By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.<br/><br/>
        Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
        </p>

        <h3>Placing Orders & Our Service</h3>
            <p>By placing a transaction with the Company, You warrant that You are legally capable of entering into binding contracts.
            <br/><br/>
            Your Information
            <br/><br/>
     
            If You wish to place an Order, You may be asked to supply certain information relevant to Your Order including, without limitation, Your name, Your credit card number, the expiration date of Your credit card, Your billing address, and Your shipping information.<br/><br/>
            You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct and complete.<br/><br/>
            By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order.
            </p>
        
        <h3>Cancellation</h3>
        <ul>
            We reserve the right to refuse or cancel Your Order at any time for certain reasons including but not limited to:
            <ul>
                <li>Service availability</li>
                <li>Errors in the description or prices for Services</li>
                <li>Errors in Your Order</li>
            </ul>
            We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.
        </ul>
        <h3>Cancellation Rights</h3>
        <p>
        Your right to cancel an Order only applies to orders that have been delayed and have gone beyond the committed timeline and have not reached your beneficiary for all remittance services: Bank Deposit, Cash Pick – Up, Door – to – Door, Cash Card Loading, Bills Payment, and Gift Delivery. Once a request for cancellation has been received, We shall validate whether the transaction has not reached or been claimed by Your beneficiary. <br/><br/>
        In the event that Your cancellation request has been approved, We will reimburse You within 14 – 30 days from the day Your request has been approved, with the service fees retained by the Company and the principal remittance amount returned.  We shall refund you in the form of cheque, which will be sent to your registered address. We reserve the right to hold your funds until such time funds are received in our Philippine office.
        </p>

        <h3>Errors, Inaccuracies, and Amendments</h3>
        <p>We are constantly updating Our offerings of services. Services available may be mis-priced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our services and in Our advertising on other websites. <br/><br/>
        We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice. <br/><br/>
        All remittance instructions processed but was not completed because of any of the following reasons: (1) when the failure to locate the beneficiary’s address was due to incorrect address; (2) the beneficiary transferred residence; (3) Area not covered (4) wrong bank; (5) wrong or incomplete name; and (6) wrong or incomplete account number, may be reprocessed within 30 days from the day it was tagged as a pending Order. A Request for reprocessing with the correct information may be done through our branches. Once we receive a request for reprocessing, we will attempt to process your Order with the submitted information. Additional charges may apply. Pending Orders above 30 days shall be considered cancelled and the amount returned, with the service fees retained by the Company. <br/><br/>
        The Company shall strictly follow the instructions in the Distribution Request sent by You.  Should there be a mistake or oversight in delivery or payment committed by Us, it shall make a reasonable effort to rectify or amend such error. However, if such mistake or oversight is not or cannot be rectified, then We warrant that You will be free from any liability whatsoever arising from non-compliance with the remittance instruction in cases of gross negligence through its own fault.
        </p>
        <h3>Price Policy</h3>
        <p>The Company reserves the right to revise its prices at any time prior to accepting an Order. <br/>
        The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence affecting delivery caused by government action, variation in increased charges, higher foreign exchange costs and any other matter beyond the control of the Company.
        </p>
        
        <h3>Payments</h3>
        <p>All Orders are subject to a one-time payment. Payment can be made through various payment methods we have available. If funds were insufficiently paid, We will not be liable for any delay or non-delivery of Your Order.  <br/><br/>
        Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order.
        </p>

        <h3>Links to Other Websites</h3>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. <br/><br/>
        The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.<br/><br/>
        We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
        </p>

        <h3>Third-Party Services</h3>
        <p>You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services. <br/><br/>
        You must comply with applicable Third parties' Terms of agreement when using our Services. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions.
        </p>

        <h3>Privacy Policy</h3>
        <p>
        The Company collects, stores, maintains, and shares information about You in accordance with Our Privacy Policy.
        <br/><br/>
        By accepting this Agreement, You acknowledge that You hereby agree and consent to the terms and conditions of Our Privacy Policy.
        </p>

        <h3>Term & Termination</h3>
        <p>
        We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions under this Agreement. <br/><br/>
        Termination of this Agreement will not limit any of the Company's rights or remedies at law or in equity in case of breach by You (during the term of this Agreement) of any of your obligations under the present Agreement. <br/><br/>
        Upon termination, Your right to use the Service will cease immediately. 
        </p>

        <h3>Indemnification</h3>
        <p>
        You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of our Services; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.
        </p>

        <h3>Limitation of Liability</h3>
        <p>The Company endeavours to make good all orders to the best of its ability. Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the full amount (Principal and Service Fee) paid by You through the Service. <br/><br/>
        To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
        </p>

        <h3>No Warranties</h3>
        <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected. <br/><br/>
        Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. <br/><br/>
        Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company, shall be solely responsible for such warranty.
        </p>

        <h3>Governing Law</h3>
        <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of our Services may also be subject to other local, state, national, or international laws.</p>

        <h3>Disputes Resolution</h3>
        <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company with their given communication channels.</p>

        <h3>Severability and Waiver</h3>
        <p>When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.</p>

        <h3>Severability</h3>
        <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

        <h3>Waiver</h3>
        <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>

        <h3>Changes to this Agreement</h3>
        <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide a notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion. <br/><br/>
        By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
        </p>

        <h3>Contact </h3>
        <h3>For any questions, concerns, or comments about our Terms & Conditions please contact us at customerservice@csg.manilaxpress.com</h3>   
   
    </div>

  );
};

export default termscondition;
