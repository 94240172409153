import react from 'react';
import TermsCondition from './containers/SignUp/TermPrivacy/termscondition';
import Footer from './containers/Footer';
import './PrivacyPolicy.css';
function terms() {
  return (
    <div>
        <TermsCondition />
        <Footer />
    </div>
  );
}

export default terms;
