import { NavLink } from "@mantine/core";

function NavbarLink({ label, link, onClick }) {
    return (
        <NavLink href={link} label={label} styles={{
            label: { fontSize: 16 },
        }} onClick={onClick} />
    );
}

export default NavbarLink;