import React from 'react';
import CreateAccount from './containers/SignUp/Create';
import Footer from './containers/Footer';

function createaccount(){
    return (
        <div className="Mnlexpress">
            <CreateAccount />
            <Footer />
        </div>

    );
}
export default createaccount;