import react from 'react';
import PrivacyPolicy from './containers/SignUp/TermPrivacy/privacypolicy';
import Footer from './containers/Footer';
import './PrivacyPolicy.css';
function privacy() {
  return (
    <div>
      <PrivacyPolicy />
        <Footer />
    </div>
  );
}

export default privacy;
