import {Box, Flex, Title, Text, Image, Group, Space} from '@mantine/core';
import CustomerTestimony from './customerTestimony';
import CustomerTestimonyMobile from './customerTestimonyMobile';
import { ForeColorPurple } from '../../lib/constants';

function CustomerTestimonials(){

    return(
        <Box id="customerTestimonials" h="100%" w='100%'>
            
            <Flex direction={{base: "column", lg:"row"}} justify={{sm:'space-between', md:'space-between', lg:'space-between'}}>
                
                <Box ml={{sm:'0px', md:'0px', lg:'40px'}} w='100%'>
                    <CustomerTestimony />
                    <CustomerTestimonyMobile />
                </Box>
                
                <Box className='Ct-directory' bg='#D9D9D9' style={{minWidth:"62%",h:'100%',paddingLeft:"15%", paddingRight:"40px"}}  mt={{base:'0px', sm:'0px', md:'0px', lg:'200px'}} py="100px">
                    
                    <Title pr='40%' style={{fontSize:{lg:40,md:36,sm:18}, color:'#323232', fontWeight: 600}}>Our Store Branches and Schedule</Title>
                    <Space h="20px"/>

                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>We’re ready to serve you in different provinces across Canada.</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress Edmonton Branch</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>​T&T 158 9450 137 Ave. NW, Edmonton, AB T5E 6C2​</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays​ 9:30 AM - 6:30 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress Calgary Branch​ </Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>945A-999 36 St. NE, Calgary, AB T2A 7X6</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays​ 9:30 AM - 6:30 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress Winnipeg Branch​</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>798 Sargent Avenue, Winnipeg, Manitoba R3E 0B8</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays​ 9:00 AM - 6:00 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress BC Richmond Branch</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>8080 Park Road, Richmond, B.C. V6Y 1T1​ </Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays​ 9:30 AM - 6:30 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress ON Scarborough Branch</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>4820 Sheppard Ave. Scarborough, Ontario​ M1S 5M8</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays​ 10:00 AM - 6:30 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>MNL Xpress Surrey Branch</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Surrey Central Mall (Inside T&T), Surrey, B.C. V3T 2W1</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Saturdays 9:30 AM - 6:30 PM</Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Sundays 11:00 AM - 3:00 PM</Text>
                    <Space h="40px"/>

                    <Text className='bold' style={{fontSize:{md:18,sm:16}, color:'#323232', fontWeight: 600}}>E-Transfers​ </Text>
                    <Text style={{fontSize:{md:18,sm:16}, color:'#323232'}}>Mondays - Sundays 9:00 AM - 6:00 PM</Text>
                    
                </Box>


                {/* For mobile view */}
                <Box className='customer-text-box' px="50px" py="100px" hiddenFrom='lg'>
                    <Text style={{textAlign:'center', fontWeight:400,fontSize:36, color:ForeColorPurple}}>Ready to send love back home?</Text>
                    <Box style={{textAlign:'center', fontWeight:600,fontSize:16}} px="50px" mt='10px'>
                        <Text span style={{fontSize:16, color:"#323232"}}>Go to the nearest </Text>
                        <Text span className='bold' style={{fontSize:16, fontWeight:"800", color:"#323232"}}>MNL Xpress </Text> 
                        <Text span style={{fontSize:16, color:"#323232"}}>branch today!</Text>
                    </Box>
                </Box>
                {/* End for mobile view */}

            </Flex>

        </Box>
    );

}

export default CustomerTestimonials;