import { Box, Flex, Grid, Title, Text, Image, Container} from '@mantine/core';
import { ForeColorPurple, ForeColorBlack } from "../../lib/constants";
import MxOption from '../../components/Option';


function Remittance(){
    const remittanceImg = "/img/remittanceImgCargo.png";
    const remittanceImgStep1 = "/img/remitanceStep1Img.png";
    const remittanceImgStep2 = "/img/remittanceStep2Img.png";
    const remittanceImgStep3 = "/img/remittanceStep3Img.png";
    const textConst = {
        option1Text : "Go to a MNL Xpress branch or authorized agent.",
        option2Text : "Send money via e-Transfer."
    };
    return(
        <Box id="remittance" w='100%'>
        
        {/* For mobile view */}
        <Box px={0} mx={0} w='100%' hiddenFrom='lg'>
            <Flex direction="column" p={40} w='100%'>
                    <Box w='100%' hiddenFrom='lg'>
                        <Title style={{color:ForeColorBlack, fontSize:36 }} ta={{base: 'center', md: 'center', lg:'left'}} >Remittance</Title>
                        <Text mt={30} ta={{base: 'center', md: 'center', lg:'left'}} style={{color:ForeColorBlack, fontWeight: 400, fontSize:16 }}>MNL Xpress offers convenient ways to send money from  Canada to the Philippines.</Text>
                        <Text className="bold" ta={{base: 'center', md: 'center', lg:'left'}} mt={30} style={{color:ForeColorBlack, fontWeight: 800, fontSize:16 }}>Here’s how to send money through MNL Xpress:</Text>
                    </Box>
                    <Box w='100%' hiddenFrom='lg'>
                        <Flex mt={30} w='100%' direction='column' style={{border: '1px solid #FFCC05'}}>
                            <MxOption ta="center" px={0} py={0} pt={10} pb={8} pl={20} pr={20} label="Option 1" style={{color:'#000000',width:'100%'}} />
                            <Text className='bold' p={20} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 800, fontSize:16 }}>{textConst.option1Text}</Text>
                        </Flex>
                        <Flex w='100%' mt={20} direction='column' style={{border: '1px solid #FFCC05'}}>
                            <MxOption ta="center" px={0} py={0} pt={10} pb={8} pl={20} pr={20} label="Option 2" style={{color:'#000000',width:'100%'}} />
                            <Text className='bold' p={20} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 800, fontSize:16 }}>{textConst.option2Text}</Text>
                            <Title px={20} style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:'24px' }}>Step 1</Title>
                            <Text mt={5} mb={20} pb={20} px={20} style={{textAlign:'left', fontWeight: 400, fontSize:16 }}>Log into your bank's online banking portal. Go to "Payment & Transfers" section (or its equivalent) and select the Interac e-Transfer option.</Text>
                            <Title px={20} style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:'24px' }}>Step 2</Title>
                            <Text mt={5} mb={20} px={20} style={{textAlign:'left', fontWeight: 400, fontSize:16 }}>Indicate the details below on the following fields:</Text>
                            <Text mt={10}  px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Email Address: </span>etransfer@manilaxpress.com</Text>
                            <Text px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Name: </span>Manila Express</Text>
                            <Text px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Amount: </span><br/>Principal Remittance Amount + Service Fee with limit of $3,000 CAD including service charge</Text>
                            <Text my={25} px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Note: </span>We will not accept and process transactions over $3,000 CAD</Text>
                            <Text mb={25} px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Message: </span><br/>Beneficiary Name, Remittance Type (e.g. Bank Deposit, Cash Pick-Up, etc.). Your email address registered in e-Transfer. Your name as registered in our branch</Text>
                            <Text mb={25} px={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Sample: </span><br/>Jose dela Cruz, Cash Pick-Up, juadelacruz@gmail.com, Juan Dela Cruz</Text>
                            <Text px={20} pb={20} style={{textAlign:'left', fontWeight: 400, fontSize:'16px' }}><span className='bold'>Note: </span>Auto-deposit is enabled for our email address. If your financial institution is requesting a security question, the email address entered is not correct.</Text>
                            <Title px={20} style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:'24px' }}>Step 3</Title>
                            <Text px={20} pb={50} mt={20}>Call our e-Transfer hotline at (604) 736-6184 and inform us about your transaction. Once processed, an e-receipt shall be issued to your email address.</Text>
                        </Flex>
                    </Box>                    
                </Flex>        
            </Box>
            {/* End for Mobile view */}  
            
            <Grid w="98%" mt={50} mx='1%' visibleFrom='lg'>
                <Grid.Col span={{base:12, lg:3}}>
                    <Flex direction="column" p={40}>
                        <Title style={{color:ForeColorBlack, fontSize:36 }} ta={{base: 'center', md: 'left', lg:'left'}} >Remittance</Title>
                        <Text mt={30} style={{textAlign:'left',color:ForeColorBlack, fontWeight: 400, fontSize:16 }}>MNL Xpress offers convenient ways to send money from  Canada to the Philippines.</Text>
                        <Text className="bold" mt={30} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 800, fontSize:16 }}>Here’s how to send money through MNL Xpress:</Text>
                        <MxOption mt={30} ta="center" px={0} py={0} pt={10} pb={8} pl={20} pr={20} label="Option 1" style={{color:'#000000',width:'150px'}}  visibleFrom='lg'/>
                        <Text className='bold' mt={20} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 800, fontSize:16 }} visibleFrom='lg'>{textConst.option1Text}</Text>                    
                    </Flex>
                </Grid.Col>
                <Grid.Col span={{base:12, md: 12, sm: 12, lg:9, xl: 9}} w='100%' visibleFrom='lg'>
                    <Box w='100%' pr='5%'>
                        <Image mx={20} className='remittance-image' src={process.env.PUBLIC_URL + remittanceImg} id='remittanceImg-image' w='100%' style={{ objectFit: 'cover', height: '100%' }} pt={120} />
                    </Box>
                </Grid.Col> 
            </Grid>

            <Grid w="98%" mt={{base:10, lg:80}} visibleFrom='lg' mx='1%'>

                <Grid.Col span={3} px={60}>
                    <Flex direction="column" >
                    <MxOption ta="center" px={0} py={0} pt={10} pb={8} pl={20} pr={20} label="Option 2" style={{color:'#000000',width:'150px'}} />
                        <Text className='bold' mt="20px" style={{textAlign:'left', fontWeight: 600, fontSize:16 }}>{textConst.option2Text}</Text>
                    </Flex>
                </Grid.Col> 

                <Grid.Col span={9} w='100%'>
                    <Flex justify='start' direction="row">
                        <Image mx={20} src={process.env.PUBLIC_URL + remittanceImgStep1} id='remittanceStep1ImgStep1-image' style={{ objectFit: 'cover', width: '50vw', height: '100%' }}   visibleFrom="lg"/>
                        <Box pr='5%'>
                            <Flex direction='column'>
                                <Title style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:36 }}>Step 1</Title>
                                <Text mt={20} style={{textAlign:'left', fontWeight: 400, fontSize:16 }}>Log into your bank's online banking portal. Go to "Payment & Transfers" section (or its equivalent) and select the Interac e-Transfer option.</Text>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex mt={60} justify='start' direction="row">
                        <Image mx={20} src={process.env.PUBLIC_URL + remittanceImgStep2} id='remittanceStep2Img-image' style={{ objectFit: 'cover', width: '50vw', height: '100%' }} visibleFrom="lg" />
                        <Box pr='5%'>
                            <Flex direction='column'>
                                <Title  style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:36 }}>Step 2</Title>
                                <Text my={20}  style={{textAlign:'left', fontWeight: 400, fontSize:16 }}>Indicate the details below on the following fields:</Text>
                                <Text mt={10}  style={{textAlign:'left', fontWeight: 400, fontSize:15 }}><span className='bold'>Email Address: </span>etransfer@manilaxpress.com</Text>
                                <Text><span className='bold'>Name: </span>Manila Express</Text>
                                <Text><span className='bold'>Amount: </span><br/>Principal Remittance Amount + Service Fee with limit of $3,000 CAD including service charge</Text>
                                <Text my={25} style={{textAlign:'left', fontWeight: 400, fontSize:12 }}><span className='bold'>Note: </span>We will not accept and process transactions over $3,000 CAD</Text>
                                <Text mb={25}><span className='bold'>Message: </span><br/>Beneficiary Name, Remittance Type (e.g. Bank Deposit, Cash Pick-Up, etc.). Your email address registered in e-Transfer. Your name as registered in our branch</Text>
                                <Text mb={25}><span className='bold'>Sample: </span><br/>Jose dela Cruz, Cash Pick-Up, juadelacruz@gmail.com, Juan Dela Cruz</Text>
                                <Text style={{textAlign:'left', fontWeight: 400, fontSize:12 }}><span className='bold'>Note: </span>Auto-deposit is enabled for our email address. If your financial institution is requesting a security question, the email address entered is not correct.</Text>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex mt="100" justify='start' direction="row">
                        <Image mx={20} src={process.env.PUBLIC_URL + remittanceImgStep3} id='remittanceStep1Img-image' style={{ objectFit: 'cover', width: '50vw', height: '100%' }} visibleFrom="lg" />
                        <Box pr='5%'>
                            <Flex direction='column'>
                                <Title  style={{textAlign:'left',color:ForeColorPurple, fontWeight: 400, fontSize:36 }}>Step 3</Title>
                                <Text mt={20}>Call our e-Transfer hotline at (604) 736-6184 and inform us about your transaction. Once processed, an e-receipt shall be issued to your email address.</Text>
                            </Flex>
                        </Box>
                    </Flex>

                </Grid.Col>
            </Grid>

        </Box>
    );
}

export default Remittance;