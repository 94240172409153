import {Box, Flex, Stack, Text, Image, Space, Container} from '@mantine/core';
import { primaryColor,primaryBgColor } from "../../lib/constants";
import Navbar from './Nav';
import { useState } from "react";
import NavbarDrawer from "./NavMobile";

function Home(){

    const [navOpen, setNavOpen] = useState(false);
    function openNanv() {
        setNavOpen(false);
    }
    
    const imgBg = "/img/mxexpress-banner.png";
    const mnlExpressImg = "/img/mnlexpress.png";

    return(
        <Box id="home" h='100vh' w='100%' bg={primaryBgColor}>
            <Flex direction={{ base: 'column', md: 'row' }} align={{ lg: 'center', sm: 'flex-start' }} h={'100%'} justify='space-between'>
                <Box w='30%' visibleFrom='lg'>
                    <Stack align="flex-start" w='100%' pr='10%'>
                        <Box pl={50} pt={50} w='100%'>
                            <Navbar />
                        </Box>
                        <Box className='home-contact'  w='100%'>
                            <Text pl={60} order={4} style={{ fontWeight: 500, color: primaryColor, fontSize:12 }}>customerservice@csg.manilaxpress.com</Text>
                            <Text pl={60} order={4} style={{ fontWeight: 500, color: primaryColor, fontSize:12 }}>(236) 200-5859</Text>
                        </Box>
                    </Stack>
                </Box>
                <Box w='100%' h='100%'>
                    <Image src={process.env.PUBLIC_URL + imgBg} id='background-image' style={{ objectFit: 'cover', height: '100%' }} visibleFrom='lg'/>
                    <Flex align="end" h='100vh' w='70%' justify='end' pb={90} pr={70} style={{ position: 'absolute', bottom: '0%', right:'0%',top: '0%', left:'30%',  textAlign: 'center', color: '#ffffff' }} visibleFrom='lg'>
                        <Flex w='100%' h='93px' mt='10px' justify='end' align='center'>
                            <Text fz={{md:36, xs:20}} style={{ fontWeight:400,color: primaryColor }} w='100%' ta='right'> Send love back home with </Text>
                        </Flex>
                        <Image mx={20} src={process.env.PUBLIC_URL + mnlExpressImg} id='mxImg-image' style={{ objectFit: 'cover', width: '100vw', maxWidth: '100%', height: '93px' }} />
                    </Flex>

                    {/* For mobile view */}
                    <Image src={process.env.PUBLIC_URL + imgBg} id='background-image' style={{ objectFit: 'cover', height: '100vh', objectPosition: '25% 75%' }} hiddenFrom='lg'/>
                    <Flex align="center" direction='column' justify='center' h='100vh' w='100%' style={{ position: 'absolute',  bottom: '5%', right:'0%',top: '0%', textAlign: 'center', color: '#ffffff' }}  hiddenFrom='lg'>                        
                        <NavbarDrawer />
                        <Text fz='18px' style={{ fontWeight:400,color: primaryColor }} mt='60px'> Send love back home with </Text>
                        <Image src={process.env.PUBLIC_URL + mnlExpressImg} id='mxImg-image' style={{ objectFit: 'cover', width: '279px', height: '73px' }} />
                    </Flex>
                    {/* End for mobile view */}
                   
                </Box>
            </Flex>
        </Box>
    );

}

export default Home;