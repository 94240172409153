import React, { useState, useEffect } from 'react';
import { Box, Text } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { ForeColorPurple } from '../../lib/constants';

function TestimonyCarousel({testimonies, activeIndex}) {
  const [activeindex, setActiveIndex] = useState(0);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    setActiveIndex(activeIndex);
  }, [activeIndex]);

  return (
    <>
      <Carousel align='start' style={{zIndex:100}} activeindex={activeindex} onSlideChange={handleSlideChange}  controls={false}>
        {testimonies.map((testimony, index) => (
          <Carousel.Slide key={index} style={{ display: index === activeindex ? 'block' : 'none' }}>
            <Box p={{base:15, sm:40, md:40 ,lg:40}} w={{base:"100%", sm:"100%",  md:"100%", lg:"400px"}} textAlign="center">
              <Text ta={{base: "center", sm:"left", md:"left", lg:"left"}} mt={{base: "10px", sm:"40px", md:"40px", lg:"40px"}} style={{fontSize:"1.25 rem", marginBottom:"20px"}}>"{testimony.message}"</Text>
              <Text ta={{base: "center", sm:"left", md:"left", lg:"left"}} className='bold' style={{fontSize:"1 rem", fontWeight:800}}>{testimony.name}, {testimony.address}</Text>
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    </>
  );
}

export default TestimonyCarousel;
