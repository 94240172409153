import NavbarLinks from "./NavLinks";
import { Button, Text } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { useNavigate } from 'react-router-dom';
import * as Icons from 'lucide-react';

function Navbar() {
    const [scroll, scrollTo] = useWindowScroll();
    const navigate = useNavigate();

    const navigateToSignup = () => {
        navigate('/sign-up');
    }

    function getOffset(id) {
        return document.getElementById(id).offsetTop;
    }

    function scrollToXrate() {
        const xRate = getOffset('Xrate');
        scrollTo({ y: xRate - 10 });
    }

    function scrollToAboutUs() {
        const aboutUs = getOffset('aboutUs');
        scrollTo({ y: aboutUs - 70 });
    }

    function scrollToRemittance() {
        const remittance = getOffset('remittance');
        scrollTo({ y: remittance });
    }

    function scrollToOtherServices() {
        const otherServices = getOffset('otherServices');
        scrollTo({ y: otherServices - 70 });
    }

    function scrollToCustomerTestimonials() {
        const customerTestimonials = getOffset('customerTestimonials');
        scrollTo({ y: customerTestimonials + 250 });
    }

    return (
        <div>
            <NavbarLinks
                links={[
                    {
                        label: "Today's Rates",
                        onClick: scrollToXrate,
                    },
                    {
                        label: "About Us",
                        onClick: scrollToAboutUs,
                    },
                    {
                        label: "Remittance",
                        onClick: scrollToRemittance,
                    },
                    {
                        label: "Other Services",
                        onClick: scrollToOtherServices,
                    },
                    {
                        label: "Customer Testimonials",
                        onClick: scrollToCustomerTestimonials,
                    },
                ]}
            />

            {/* Add Create Account Button */}
            <Button color="#ffcc05"
                h = {{xs:50, md:60}}
                w= {{xs:200, md:200}}
                px={{xs: 24, md:24}}
                style={{
                    
                    height: "60px",
                    width: "250px",
                    fontSize: "24px",
                    fontFamily: "Salmond",
                    borderRadius: "50px",
                    color: 'black',
                    border: '2px solid #ffcc05',
                    marginTop: "300px"

                }} 
                onClick={navigateToSignup}
                >
                            
                <Text style={{fontSize: '16px'}}>Create an Account</Text>
                <Icons.ArrowRight style={{marginBottom: '3px', marginLeft: '30px'}} />
                </Button>
            
        </div>
    );
}

export default Navbar;
