import {Box, Flex, Title, Text, Image, Group} from '@mantine/core';
import { primaryColor,ForeColorPurple } from "../../lib/constants";


function ChooseTheRemittance(){

    const creditToAccount = "/img/creditToAccount.png";
    const d2dDelivery = "/img/d2dDelivery.png";
    const cashPickup = "/img/cashPickup.png";

    return(
        <Box className='chooseRemit' h={{base:'100%', sm: '100%', md: '100%', lg:'100%'}} mt={{base:'10px',sm: '10px', md: '10px', lg:'150px'}}>
            <Title mx={{base:'15%', sm: '22%', md: '22%', lg:'22%'}} my={{base:5, lg:2}} style={{textAlign:"center",fontWeight:400, color:'#323232'}} fz={{base:'24px', lg:'36px'}}>Choose the remittance option that works best for your loved ones back home:</Title>
            <Flex align="center" w="100%" px={4} direction={{base:'column',sm:'column', md:'row', lg:'row'}} h={{base:'100%', sm: '100%', md: '100%', lg:'560px'}} gap="lg" mt={{base:20, lg:80}} justify='center' wrap='wrap' p={{base:40,sm:0, md:0, lg:0}}>
                
                <Box bg={ForeColorPurple} w={{base: "100%", sm: "80%", md: "80%", lg:"300px"}} h="100%" p={40}>
                    <Box>
                        <Flex direction='row' justify={{base:'center', lg:'start'}} w='100%' wrap={{base:'no-wrap',lg:'wrap'}}>
                            <Flex w={{base:'30%', lg: '100%'}} justify={{base:'end', lg:'start'}} align='center'>
                                <Image
                                w={{base:'30%', lg: '100%'}}
                                src={process.env.PUBLIC_URL + creditToAccount}
                                style={{ objectFit: 'cover', width: '75px', height: '75px' }}
                                />
                            </Flex>
                            <Flex w={{base:'70%', lg: '100%'}} justify={{base:'start', lg:'start'}} align='center'>
                                <Text ta={{base:'left', lg:'left'}} pr={{base:0, lg:80}} ml={{base:'20px',lg:'0px'}} fz={{base:'20px', lg:'28px'}} mt={{base:10, lg:80}} style={{fontWeight:400, color:primaryColor}}>Credit to Account</Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <Group w='100%'>
                        {/* <Text w='100%' mt={15} style={{fontWeight:400, color:primaryColor, fontSize:18 }}>
                            Remittance will be <br/>credited to the Philippine <br/>bank account endorsed<br/> by the sender.
                        </Text> */}
                        <Text ta={{base:'center', lg:'left'}} w='100%' mt={{base:40, lg:30}} fz={{base:'16px', lg:'18px'}} style={{fontWeight:400, color:primaryColor }}>
                            Remittance will be credited to the Philippine bank account endorsed by the sender.
                        </Text>
                    </Group>
                </Box>

                <Box bg={ForeColorPurple} w={{base: "100%", sm: "80%", md: "80%", lg:"300px"}} h="100%" p={40}>
                    <Box>
                        <Flex direction='row' justify={{base:'center', lg:'start'}} w='100%' wrap={{base:'no-wrap',lg:'wrap'}}>
                            <Flex w={{base:'30%', lg: '100%'}} justify={{base:'end', lg:'start'}} align='center'>
                                <Image
                                w={{base:'30%', lg: '100%'}}
                                src={process.env.PUBLIC_URL + cashPickup}
                                style={{ objectFit: 'cover', width: '75px', height: '75px' }}
                                />
                            </Flex>
                            <Flex w={{base:'70%', lg: '100%'}} justify={{base:'start', lg:'start'}} align='center'>
                                <Text ta={{base:'left', lg:'left'}} pr={{base:0, lg:80}} ml={{base:'20px',lg:'0px'}} fz={{base:'20px', lg:'28px'}} mt={{base:10, lg:80}} style={{fontWeight:400, color:primaryColor}}>Cash Pickup</Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <Group w='100%'>
                        {/* <Text pr={80} mt={80}  style={{fontWeight:400, color:primaryColor, fontSize:28 }}>Cash <br/>Pickup</Text> */}
                        <Text ta={{base:'center', lg:'left'}} w='100%' mt={{base:40, lg:30}} fz={{base:'16px', lg:'18px'}} style={{fontWeight:400, color:primaryColor }}>
                            The receiver will present an issued control number to any U Store or other payout partners to get their remittance.
                        </Text>
                        <Box visibleFrom='lg'>
                            <div style={{ fontWeight: 500,fontSize:"10px", color: primaryColor }}>Click here to find a U Store branch near you. <br/>
                                <a href='https://ussc.com.ph/branches/' style={{ color: primaryColor, textDecoration: 'none' }}>ussc.com.ph/branches</a>
                            </div>
                        </Box>

                        {/* For mobile view */}
                        <Box hiddenFrom='lg' w='100%'>
                            <div style={{ textAlign:'center', fontWeight: 500,fontSize:"10px", color: primaryColor, width: '100%' }}>
                                Click here to find a U Store branch near you.<br />
                                <a href='https://ussc.com.ph/branches/' style={{ color: primaryColor, textDecoration: 'underline' }}>ussc.com.ph/branches</a>
                            </div>
                        </Box>
                        {/* End For mobile view */}
                    </Group>
                    
                </Box>

                <Box bg={ForeColorPurple} w={{base: "100%", sm: "80%", md: "80%", lg:"300px"}} h="100%" p={40}>
                    <Box>
                        <Flex direction='row' justify={{base:'center', lg:'start'}} w='100%' wrap={{base:'no-wrap',lg:'wrap'}}>
                            <Flex w={{base:'30%', lg: '100%'}} justify={{base:'end', lg:'start'}} align='center'>
                                <Image
                                w={{base:'30%', lg: '100%'}}
                                src={process.env.PUBLIC_URL + d2dDelivery}
                                style={{ objectFit: 'cover', width: '75px', height: '75px' }}
                                />
                            </Flex>
                            <Flex w={{base:'70%', lg: '100%'}} justify={{base:'start', lg:'start'}} align='center'>
                                <Text ta={{base:'left', lg:'left'}} pr={{base:0, lg:80}} ml={{base:'20px',lg:'0px'}} fz={{base:'20px', lg:'28px'}} mt={{base:10, lg:80}}  style={{fontWeight:400, color:primaryColor}}>Door to Door <br/>Delivery</Text>
                            </Flex>
                        </Flex>
                    </Box>
                    <Group w='100%'>
                        <Text ta={{base:'center', lg:'left'}} w='100%' mt={{base:40, lg:30}} fz={{base:'16px', lg:'18px'}} style={{fontWeight:400, color:primaryColor }}>
                            The remittance will be delivered straight to the recipient's address endorsed during the sendout process.
                        </Text>
                    </Group>
                    
                </Box>

            </Flex>
        </Box>

    );
}

export default ChooseTheRemittance;