import React from 'react';
import WelcomePage from './containers/SignUp/Welcome';

import Footer from './containers/Footer';



function signup(){
    return (
        <div className="Mnlexpress">
            <WelcomePage />
            <Footer />
        </div>

    );
}

export default signup;
