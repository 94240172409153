import { httpClient } from './base/httpClient';
import { API_URL } from '../constants/api-url';

const xRates = {
  getList: async (url, data) => {
    let isSuccess = false;
    const payload = {};
    const response = await httpClient.post(API_URL.GET_XRATES_LIST, payload);
    if (response.data?.status === 1) {
      isSuccess = true;
    }

    return {
      success: isSuccess,
      data: (isSuccess) ? response.data?.results : null,
      error: (!isSuccess) ? response.message : null
    };
  },
};

export default xRates;
