import React, { useState, useEffect } from 'react';
import {Box, Flex, Title, Container, Image, Grid} from '@mantine/core';
import TestimonyCarousel from './testimonyCarousel';
import { ForeColorPurple } from '../../lib/constants';

function CustomerTestimonyMobile()
{
    const [activeindex, setActiveIndex] = useState(0);
    const customerTestimonials = "/img/customerTestimonials.png";

    const testimonies = [
        {
          "message": "I'm so glad I found MNL Xpress! My parents in the Philippines finally get their money on time, and I don't have to deal with long lines or confusing forms. Plus, their customer service is always helpful.",
          "name": "Josephine Villegas",
          "address": "Ontario"
        }
      ]
    ;

    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex(activeindex => (activeindex + 1) % testimonies.length);
        }, 5000); 
    
        return () => clearInterval(interval);
      }, [testimonies.length]);

    const renderIndicators = () => {
        return Array.from({ length:testimonies.length }, (_, index) => (
          <div
            
            key={index}
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: index === activeindex ? ForeColorPurple : '#ccc',
              margin: '10px 5px',
              cursor: 'pointer',
            }}
            onClick={() => handleSlideChange(index)}
          />
        ));
    };

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    return(
        <Box h='100%' w='100%' mt='15%' hiddenFrom='lg'> 
            
            <Flex w="100%" h="100%" direction="row" align='center' justify='center' gap={10}>
              <Box>
                <Image  className='CT-image'
                      src={process.env.PUBLIC_URL + customerTestimonials}
                      style={{  width: '39px', height: '78.42px' }}/>
              </Box>
              <Box>
                <Title px={10} style={{ fontWeight:'400', fontSize:'36px' }}> 
                    Customer<Title style={{ fontWeight:'400', fontSize:'36px' }}>Testimonials
                    </Title>
                </Title>
              </Box>
            </Flex>

            <Flex align='center' justify='center' h='100%' w='100%'>
                <TestimonyCarousel testimonies={testimonies} activeIndex={activeindex}/>
            </Flex>

            <Flex align='center' justify='center' h='100%' w='100%' mb={'15%'} mt='3%'>
                <Box className='Ct-carousel-bullets'>
                        {renderIndicators()}
                </Box>
            </Flex>
            
        </Box>
    );


}

export default CustomerTestimonyMobile;