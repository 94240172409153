import axios from 'axios';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    // 'Content-Type': 'application/json',
    'Content-Type': 'multipart/form-data',
    "Api-Key": process.env.REACT_APP_API_KEY,
    Accept: 'application/json',
    // eslint-disable-next-line camelcase, object-shorthand
    // sess_id: sess_id,
  },
});
