import React, { useState }  from 'react';
import { Container, Grid, Button, Text, Title, Image, Anchor,Modal,List, ListItem,Checkbox,Flex } from '@mantine/core';
import { primaryColor,primaryBgColor } from "../../lib/constants";
import ReCAPTCHA from 'react-google-recaptcha';
import { useDisclosure } from '@mantine/hooks';
import { MoveRight } from 'lucide-react';
import { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Icons from 'lucide-react';
import { useMediaQuery } from '@mantine/hooks';

function Welcome(){
    useEffect(() => {
        // Clear the values once validation is successful
        return () => {
            // localStorage.removeItem('captcharef');
            // localStorage.removeItem('termsref');
        };
    }, []);
    const [captchaValue, setCaptchaValue] = useState(null);
    const captcharef = false;
    const termsref = false;
    const navigate = useNavigate();
    
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (!captchaValue) {
            showError('Please accept the terms and solve the captcha')
            return;
        }else{
            localStorage.setItem('captcharef', true);
        }
        if (!document.getElementById('checkboxId').checked) {
            showError('Please Accept the Terms and Conditions and Data Privacy Policy')
            return;
        }else{
            localStorage.setItem('termsref', true);
        }
        // console.log('Captcha '+captchaValue);
        // console.log('TandC '+document.getElementById('checkboxId').checked);
    //   window.location.href = '/createaccount';
        navigate('/create-account');
    };

    const showError = (error_msg, custom_msg = '') => {
        Swal.fire({
          title: 'Error',
          text: error_msg,
          icon: 'error',
          html: custom_msg,
          confirmButtonText: 'OK'
        });
      }

    const [opened, { open, close }] = useDisclosure(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    return(
        <div  style={{ 
            background: 'rgb(2,0,36)',
            background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(92,39,120,1) 0%, rgba(57,9,83,1) 100%)',
            minHeight: 'calc(100vh - 60px)',
            padding: '40px',
            position: 'relative',
            fontFamily: 'SalmondRegular',

            }}>
                {/* <img 
                    src="./img/malemodel.png" 
                    className='img-model'
                    style={{
                        position: 'absolute',
                        top: '60px', 
                        right:  0, 
                        width: '75%', 
                        height: 'calc(96vh - 115px)',
                        borderRadius: '8px', 
                        objectFit: 'cover', // Cover the container
                        transform: 'translateX(5%)', // Crop right part of the image
                        overflow: 'hidden',
                        zIndex: '0',
                        marginTop: '50px'
                        //make the image at back of the content
                        
                     
                      }} 
                /> */}

            <Container size="lg">
                {/* add to column */}
                <Grid>
                    <Grid.Col className='heTitle' span={{base:12, md:6}} xs={12} style={{textAlign: 'left'}}>
                        <Title className='beforewe' order={1} >Before we begin</Title>
                        
                        <Text style={{
                            color: 'white',
                            fontSize:'18px',
                            marginBottom: '20px',
                            marginTop: '20px'
                            }}>This sign-up process will let you create your own MNL Xpress account, which is required for making online money transfers to the Philippines.
                        </Text>
                        <Text style={{
                            color: 'white',
                            fontSize:'18px',
                            marginBottom: '30px',
                            }}>Before we continue, please prepare:
                        </Text>
                                <List style={{color: 'white'}}>
                                    <List.Item style={{
                                        marginBottom: '30px'
                                    }}>
                                        <Title className='iboldmo' style={{
                                            fontSize: '18px',
                                            lineHeight: '30px',
                                           

                                        }}>1. Your email address</Title>
                                        <List withPadding>
                                            Make sure that the email address is valid, and is the
                                            same email address to be used for your e-transfer transactions.
                                           
                                        </List>
                                    </List.Item>

                                    <List.Item style={{
                                        marginBottom: '30px'
                                    }}><Title  className='iboldmo' style={{
                                        fontSize: '18px',
                                        lineHeight: '30px',
                                       

                                    }}>2. A photo of your face</Title>
                                        <List withPadding >
                                            Your face should be shown in full, well-lit and without any obstructions.
                                            
                                        </List>
                                    </List.Item>
                                    <List.Item style={{
                                        marginBottom: '30px'
                                    }}
                                    ><Title className='iboldmo' style={{
                                        fontSize: '18px',
                                        lineHeight: '30px',
                                       

                                    }}>3. A photo of one (1) valid ID</Title>
                                        <List withPadding>
                                            Make sure to crop or show only the ID in the photo.The ID details must be clear and readable.
                                            
                                        </List>
                                        <br></br>
                                        <Anchor className='iboldmo' onClick={open} fz="xl" 
                                        style={{
                                            color: '#FFCC05', 
                                            cursor: 'pointer', 
                                            zIndex: 1,
                                            position: 'relative',
                                            textDecoration: 'underline'
                                          }}>List of Acceptable IDs</Anchor>
                                    </List.Item>
                                </List>
                                <List style={{color: 'white',
                                    marginBottom: '30px',
                                    marginTop: '30px'
                                }}>
                                    <List.Item style={{
                                        marginBottom: '30px'
                                    }}>
                                        <Text fw={1000}> After you sign-up, your account will be subject to verification to confirm your identity.</Text>
                                       
                                    </List.Item>
                                </List>
                            <Modal opened={opened} onClose={close} size="60rem" centered withCloseButton={false} fullScreen={isMobile}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',marginTop:'20px' }}>
                                <Title className='iboldmo1'>List of valid IDs</Title>
                                <Icons.CircleX 
                                    size={30} 
                                    style={{ marginRight: '10px', cursor: 'pointer' }} 
                                    onClick={close} 
                                />
                                    
                                </div>
                                <List withPadding listStyleType="disc">
                                    <List.Item><Text className='iboldmo'>Canadian Passport</Text></List.Item>
                                    <List.Item><Text className='iboldmo'>Permanent Resident Card</Text></List.Item>
                                    <List.Item><Text className='iboldmo'>Secure Certificate of Indian Status</Text></List.Item>
                                    <List.Item><Text className='iboldmo'>Driver’s Licence</Text></List.Item>
                                    <List.Item><Text className='iboldmo'>British Columbia Services Card</Text></List.Item>
                                    <List.Item><Text className='iboldmo'>Provincial or territorial identity cards</Text>
                                        <List withPadding listStyleType="disc">
                                            <Grid>
                                                <Grid.Col span={{base:12, md:6}}>
                                                    <List.Item>British Columbia Enhanced ID</List.Item>
                                                    <List.Item>Alberta Photo Identification Card</List.Item>
                                                    <List.Item>Saskatchewan Non-driver photo ID</List.Item>
                                                    <List.Item>Manitoba Enhanced Identification Card</List.Item>
                                                    <List.Item>Manitoba Enhanced Identification Card</List.Item>
                                                    <List.Item>New Brunswick Photo ID Card</List.Item>
                                                </Grid.Col>
                                                <Grid.Col span={{base:12, md:6}}>
                                                    <List.Item>Nova Scotia Identification Card</List.Item>
                                                    <List.Item>Prince Edward Island Voluntary ID</List.Item>
                                                    <List.Item>Newfoundland and Labrador Photo Identification Card</List.Item>
                                                    <List.Item>Northwest Territories General Identification Card</List.Item>
                                                    <List.Item>Nunavut General Identification Card</List.Item>
                                                </Grid.Col>
                                            </Grid>
                                        </List>
                                    </List.Item>
                                    
                                </List>
                                
                            </Modal>
                            <form onSubmit={handleSubmit}>
                            <Checkbox
                                style={{
                                    color: 'white',
                                    marginBottom: '30px',
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                                id="checkboxId"
                                label={
                                    <span>
                                    I agree with the{" "}
                                    <a href={process.env.PUBLIC_URL + "/terms"} style={{ color: 'white', textDecoration: 'underline' }} target="_blank">
                                        Terms and Conditions
                                    </a>{" "}
                                    and{" "}
                                    <a href={process.env.PUBLIC_URL + "/privacy"} style={{ color: 'white', textDecoration: 'underline' }} target="_blank">
                                        Data Privacy Policy
                                    </a>.
                                    </span>
                                }
                                />

                    
                                <ReCAPTCHA
                                 className='recaptcha'
                                style={{
                                    marginBottom: '30px',
                                    marginRight: '50px',
                                    zIndex: 1,
                                    position: 'relative'
                                }}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={handleCaptchaChange}
                                />
                                <Button color="#FFCC05"
                                    h = {{xs:50, md:60}}
                                    w= {{xs:200, md:200}}
                                    px={{xs: 24, md:24}}
                                    style={{
                                        
                                        height: "60px",
                                        width: "200px",
                                        fontSize: "24px",
                                        fontFamily: "Salmond",
                                        borderRadius: "50px",
                                        color: 'black',
                                        border: '2px solid #ffcc05',
                                        position: 'relative',
                                        zIndex: 1,

                                    }} type='submit'>
                            
                                <Text style={{fontSize: '20px'}}>Continue </Text>
                                <Icons.ArrowRight style={{marginBottom: '3px', marginLeft: '30px'}} />
                                </Button>
                            </form>
                            

                    </Grid.Col>
                    <Grid.Col span={{base:12, md:2}} className='hideme'></Grid.Col>
                    <Grid.Col span={{base:12, md:4}} className='hideme'>
                        <Flex direction='column' justify='center' align='center' gap="md">
                            <Flex w='100%' h='100%' justify='end'>
                                <img 
                                    className='ig-mnl'
                                    src="./img/mnlexpress.png" 
                                    style={{
                                        width: '290px', 
                                        height: '70px', 
                                        borderRadius: '8px',  
                                    }} 
                                />
                                <Image 
                                    src="./img/malemodel.png" 
                                    className='img-model'
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        bottom: '2px',
                                        width: '100%',
                                        // borderRadius: '8px', 
                                        objectFit: 'contain', // Cover the container
                                        objectPosition: "70% 100%",
                                        // transform: 'translateX(5%)',
                                        // overflow: 'hidden',
                                        // marginTop: '75px',
                                        // overflow: 'hidden',
                                        height: "100vh",
                                        marginLeft: '200px',
                                        
                                        //make the image at back of the content
                                        
                                    
                                    }} 
                                />
                            </Flex>
                        </Flex>
                    </Grid.Col>

                </Grid>

            </Container>
        </div>
    );  

}
export default Welcome;