import react from 'react';

function ErrorPage() {
    return (
        <div style={{
            margin: 0,
            padding: 0,
            fontFamily: 'Arial, sans-serif',
            backgroundColor: '#b8d5e0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <div style={{ textAlign: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="96" height="96" style={{ fill: '#444' }}>
                    <path d="M12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0zm1.36 17.72h-2.72V10h2.72v7.72zM12 8.82a1.64 1.64 0 110-3.28 1.64 1.64 0 010 3.28z" />
                </svg>
                <h1 style={{ fontSize: '48px', color: '#333' }}>Page Not Found</h1>
                <p style={{ fontSize: '18px', color: '#666' }}>Sorry, the page you're looking for doesn't exist.</p>
            </div>
        </div>
    );
}

export default ErrorPage;

