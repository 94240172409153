import React, { useState, useEffect } from 'react';
import { Box, Flex, Title, Text, Space } from '@mantine/core';
import { primaryColor, ForeColorPurple, ForeColorYellow } from "../../lib/constants";
import OtherServicesCarousel from './otherServicesCarousel';

function OtherServices() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(activeindex => (activeindex + 1) % 2);
        }, 6000);
        return () => clearInterval(interval);
    }, []);

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    const renderIndicators = () => {
        return Array.from({ length: 2 }, (_, index) => (
            <div
                key={index}
                style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: index === activeIndex ? ForeColorYellow : '#ccc',
                    margin: '0 5px',
                    cursor: 'pointer',
                }}
                onClick={() => handleSlideChange(index)}
            />
        ));
    };


    return (
        <Box id='otherServices'  h={{base:'100%',  sm: '100%', md: '100%',  lg:'75vh'}} mt={{base:50, sm: 50, md: 50,  lg:200}}>
            <Flex h={{base:'100%', sm:'100%', md:'100%',  lg:'80vh'}} w='100%' direction={{base:'column', sm:'column', md:'column', lg: 'row'}}>
                <Box w={{base:'100%',sm:'100%',md:'100%', lg:'52vw'}} h={{base: '50vh',sm: '80vh', md: '80vh', lg:'80vh'}} mb={0}>   
                    <OtherServicesCarousel activeIndex={activeIndex}/>
                </Box>
                
                {/* <Flex width="50%" mt="-50px" pos="absolute" left="20vw" top="673vh" direction="row" justifyContent="center" ml="auto">
                    {renderIndicators()}
                </Flex> */}

                <Box className='otherServices' w='100%' h={{base:'100%', sm: '100%', md: '100%', lg:'80vh'}} bg={ForeColorPurple} px={40} pt={80} pb={{base:60, lg:40}}>
                    <Title style={{ fontWeight: 500, color: primaryColor, fontSize: '36px' }}>Other Services</Title>

                    <Space h={50} />
                    <Text className={`Os-title ${activeIndex === 0 ? 'bold' : ''}`} mb={20} style={{ fontWeight: activeIndex === 0 ? 700 : 500, color: activeIndex === 0 ? ForeColorYellow : primaryColor, fontSize: '20px', cursor: 'pointer' }} onClick={() => handleSlideChange(0)}>Bills Payment</Text>
                    <Text className="Os-content" style={{ fontWeight: 500, color: primaryColor }}>Pay your SSS, Pag-IBIG, utilities, and other Philippine-merchants through our secure and convenient bills payment services.</Text>

                    <Space h={50} />
                    <Text className={`Os-title ${activeIndex === 1 ? 'bold' : ''}`} mb={20} style={{ fontWeight: activeIndex === 1 ? 700 : 500, color: activeIndex === 1 ? ForeColorYellow : primaryColor, fontSize: '20px', cursor: 'pointer' }} onClick={() => handleSlideChange(1)}>Cash Card Loading</Text>
                    <Text className="Os-content" style={{ fontWeight: 500, color: primaryColor }}>Top up your ATM Visa Cash Card at any MNL Xpress branch.</Text>

                </Box>
            </Flex>

        </Box>
    );

}

export default OtherServices;
