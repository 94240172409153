export const links_suffix = {
    SendEmailOtp: 'send_email_otp',
    VerifyEmailOtp: 'verify_email_otp',
    ValidateEmail: 'validate_email',
    ValidateMobile: 'validate_mobile_no',
    ValidateOccupation: 'validate_occupation',
    ValidatePersonalInfo: 'validate_personal_info',
    GetUserDetails: 'get_user_details',
    GetUserImage: 'get_user_images',
    CreateUserAccount: 'create_account',
    UpdateUserAccount: 'update_account',
    GetDocumentTypes: 'get_document_types',
    
}