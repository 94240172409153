import React, { useState } from 'react';
import * as Mantine from '@mantine/core';
import {Box, Flex, Stack, Title, Text, Image, Stepper, rem, Progress} from '@mantine/core';
import * as Icons from 'lucide-react';
import {useParams, useNavigate} from 'react-router-dom';
import { DatePickerInput } from '@mantine/dates';
import { createTheme } from '@mantine/core';
import { ForeColorYellow, ForeColorBlack } from "../../lib/constants";
import { useEffect } from 'react';
import { httpClient } from './api/base/httpclient';
import { links_suffix } from './constant/links-suffix';
import { format } from 'date-fns';
import { se } from 'date-fns/locale';
import Swal from 'sweetalert2';
import AuthCode from 'react-auth-code-input';
import { IconChevronDown } from '@tabler/icons-react';
import ReactGA from 'react-ga4';


const steps = [
  { label: 'Personal Information', icon: <Image src={process.env.PUBLIC_URL + '/img/icon-user-completed.png'} style={{ width: rem(30), height: rem(30) }} />, icon_completed: <Image src={process.env.PUBLIC_URL + '/img/icon-user-completed.png'} style={{ width: rem(30), height: rem(30) }} /> },
  { label: 'Contact Details', icon: <Image src={process.env.PUBLIC_URL + '/img/icon-phone.png'}  style={{ width: rem(30), height: rem(30) }} /> , icon_completed: <Image src={process.env.PUBLIC_URL + '/img/icon-phone-completed.png'}  style={{ width: rem(30), height: rem(30) }} /> },
  { label: 'Verify Identity', icon: <Image src={process.env.PUBLIC_URL + '/img/icon-user-check.png'}  style={{ width: rem(30), height: rem(30) }} /> , icon_completed: <Image src={process.env.PUBLIC_URL + '/img/icon-user-check-completed.png'}  style={{ width: rem(30), height: rem(30) }} /> },
  { label: 'Review Details', icon: <Image src={process.env.PUBLIC_URL + '/img/icon-clipboard.png'}  style={{ width: rem(30), height: rem(30) }} /> , icon_completed: <Image src={process.env.PUBLIC_URL + '/img/icon-clipboard-completed.png'}  style={{ width: rem(30), height: rem(30) }} /> },
  { label: 'Verify Email', icon: <Image src={process.env.PUBLIC_URL+ '/img/icon-mail.png'}  style={{ width: rem(30), height: rem(30) }} /> , icon_completed: <Image src={process.env.PUBLIC_URL + '/img/icon-mail-completed.png'}  style={{ width: rem(30), height: rem(30) }} /> },
];
const theme = createTheme({
    fontFamily: 'SalmondRegular',
    colorScheme: 'light',
    primaryColor: 'violet',

});


export default function MultiStepForm() {
    const navigate = useNavigate();
    useEffect(() => {
        const captcharef = localStorage.getItem('captcharef');
        const termsref = localStorage.getItem('termsref');
    
        if (!captcharef || !termsref) {
            showError('Please accept the terms and solve the captcha')
            navigate('/sign-up');
        }
    }, []);

    const checkicon = <Icons.CheckCircle size={24} />;
    const ErrorIcon = <Icons.AlertCircle size={24} />;
    const [activeStep, setActiveStep] = useState(0);
    const [dvalue,dsetvalue] = useState(new Date());
    // Function to handle Next/Back button clicks
    const nextStep = () => setActiveStep((current) => (current < steps.length - 1 ? current + 1 : current));
    const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));
    const [checked, setChecked] = useState(false);
    const [resendEmailStatus, setResendEmailStatus] = useState(0);
    const [emailVerifed, setEmailVerifed] = useState(0);
    const [image, setImage] = useState();
    const [image1, setImage1] = useState();
    const [userDocumentDisplay, setUserDocumentDisplay] = useState();
    const [userPhotoDisplay, setUserPhotoDisplay] = useState();
    const [errorFields, setErrorFields] = useState([]);
    const [modifiedFields, setModifiedFields] = useState([]);
    const [otpCode, setOtpCode] = useState();
    const [documentTypes, setDocumentTypes] = useState([]);
    const [allDocsList, setAllDocsList] = useState();
    
    const validateFields = (arr_item) => {
      const fielsToValidate = [
       [
          'first_name',
          'last_name',
          'occupation',
          'birth_date_string'
        ],
        [
          'email',
          'mobile_no',
          'home_office_no',
          'present_address',
          'present_municipality',
          'present_zip_code'
        ],
        [
          'id_number',
          'expiration_date_string',
          'file_name',
          'photo_filename',
          'document_type'
        ]
      ]
      setErrorFields([]);
      let countErr = 0;
      fielsToValidate[arr_item].forEach((element) => {
        
        if(element == 'file_name'){
          if(userDocumentDisplay == '' || userDocumentDisplay == undefined){
            countErr += 1;
            setErrorFields(errorFields => [...errorFields, 'file_name'])
          }
        }else if(element == 'photo_filename'){
          if(userPhotoDisplay == '' || userPhotoDisplay == undefined){
            countErr += 1;
            setErrorFields(errorFields => [...errorFields, 'photo_filename'])
          }
        } else {
          if(formData[element] == '' || formData[element] == null){
            countErr += 1;
            setErrorFields(errorFields => [...errorFields, element])
          }
        }
      });

      if(countErr > 0){
        return true;
      }

      return false;
    }

    //THIS CODE IS FOR RESENDING EMAIL OTP
    const resendEmail = async () => {
        try {
          showLoader('Resending OTP...')
          const emailOTP = await httpClient.post(links_suffix.SendEmailOtp, {
              email: formData.email, // Send the email in the request body
          });
          if (emailOTP?.data?.status == 1) {
              setResendEmailStatus(1);
              closeLoader();
              showSuccess('Email sent!');
          }else{
              closeLoader();
              showError(emailOTP.data?.message || 'Failed to send email!');
          }
          clearOtpField();
        } catch (error) {
          closeLoader();
          showError(error)
        }
  
    }

    const onUserDocumentChange = (file) => {
      if(file){
        setUserDocumentDisplay(URL.createObjectURL(file));
        setImage(file);
        setModifiedFields(modifiedFields => [...modifiedFields, "file_name"])
      }
    }

    const onUserPhotoChange = (file) => {
      if(file){
        setUserPhotoDisplay(URL.createObjectURL(file));
        setImage1(file);
        setModifiedFields(modifiedFields => [...modifiedFields, "photo_filename"])
      }
    }

    const handleOnCodeChange = (pin) => {
      setOtpCode(pin);
    }

    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        birth_date: '',
        birth_date_string: '',
        occupation: '',
        email: '',
        mobile_no: '',
        home_office_no: '',
        present_address: '',
        present_municipality: '',
        present_zip_code: '',
        id_number: '',
        expiration_date: '',
        expiration_date_string: '',
        file_name: '',
        photo_filename: '',
        promo_email : '',
        city_prov_state : '',
        referral_code : '',
        document_type : '',
        document_type_string : ''
    });

    const clearOtpField = () => {
        // setOtpCode('');
    }

    const showLoader = (loader_title) => {
      Swal.fire({
        title: loader_title,
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
    }

    const closeLoader = () => {
      Swal.close();
    }

    const showSuccess = (succes_msg) => {
      Swal.fire({
        title: 'Success',
        text: succes_msg,
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }

    const showError = (error_msg, custom_msg = '') => {
      Swal.fire({
        title: 'Error',
        text: error_msg,
        icon: 'error',
        html: custom_msg,
        confirmButtonText: 'OK'
      });
    }
    
    const handleInputChange = (e) => {
        if (e.target) {
            // Regular input fields
            const { name, value } = e.target;
            setModifiedFields(modifiedFields => [...modifiedFields, name])
            setFormData({
              ...formData,
              [name]: value,
            });
          } else if (e && e.name) {
            // File input or other custom components
            setFormData({
              ...formData,
              file_name: e['name'], // Handle the file object or other values
              photo_filename: e['name'],
            });
          }
      };

    const checkForModified = (field_name) => {
      if(modifiedFields.includes(field_name)){
        
        // Ignore photo fields
        if(field_name != 'file_name' && field_name != 'photo_filename'){
          // return false if field name is null
          if(formData[field_name] == ''){
            return false;  
          }else{
            return true;
          }
        }else{
          return true;
        }
       
      } else{
        return false;
      }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(activeStep === 0){
          //check if the fields are empty
          if(validateFields(0)){
            return;
          }
            // if(formData.first_name == '' || formData.last_name == '' || formData.birth_date == '' || formData.occupation == ''){
            //     showError('Please fill up all required fields.');
            //     return;
            // }
          try {
            showLoader('Validating data...')
            // Validate occupation
            const validateOccupation = await httpClient.post(links_suffix.ValidateOccupation, {
              occupation: formData.occupation,
            });
            if (validateOccupation?.data?.status == 0) {
              let addition_err_msg = '';
              if(validateOccupation?.data?.errors){
                let err = validateOccupation.data.errors;
                Object.keys(err).forEach(function(key) {
                  addition_err_msg += '<p>'+err[key]+'</p>';  
                });
              }
              closeLoader();
              showError(validateOccupation?.data?.message || 'Invalid occupation. Please try again.', `${addition_err_msg}`);
            }

            // Validate personal info
            const validatePersonalInfo = await httpClient.post(links_suffix.ValidatePersonalInfo, {
              first_name: formData.first_name,
              middle_name: formData.middle_name,
              last_name: formData.last_name,
              birth_date: formData.birth_date_string,  
            });
            if (validatePersonalInfo?.data?.status == 0) {
              let addition_err_msg = '';
              if(validatePersonalInfo?.data?.errors){
                let err = validatePersonalInfo.data.errors;
                Object.keys(err).forEach(function(key) {
                  addition_err_msg += '<p>'+err[key]+'</p>';  
                });
              }
              closeLoader();
              showError(validatePersonalInfo?.data?.message || 'Invalid personal information. Please try again.', `${addition_err_msg}`);
            }

            if (validatePersonalInfo?.data?.status == 1 && validateOccupation?.data?.status == 1) {
              closeLoader();
              nextStep();
            }
            
          } catch (error) {
              closeLoader();
              showError('Error. Please try again.');
          }

        }else if (activeStep === 1) {
            //check if the fields are empty
            if(validateFields(1)){
              return;
            }
          try{
            showLoader('Validating data...')
            // Validate email
            const validateEmail = await httpClient.post(links_suffix.ValidateEmail, {
              email: formData.email,
            });
            if (validateEmail?.data?.status == 0) {
              let addition_err_msg = '';
              if(validateEmail?.data?.errors){
                let err = validateEmail.data.errors;
                Object.keys(err).forEach(function(key) {
                  addition_err_msg += '<p>'+err[key]+'</p>';  
                });
              }
              closeLoader();
              showError(validateEmail?.data?.message || 'Invalid email. Please try again.', `${addition_err_msg}`);
            }

            // Validate mobile no
            const validateMobileNo = await httpClient.post(links_suffix.ValidateMobile, {
              mobile_no: formData.mobile_no,
            });
            if (validateMobileNo?.data?.status == 0) {
              let addition_err_msg = '';
              if(validateMobileNo?.data?.errors){
                let err = validateMobileNo.data.errors;
                Object.keys(err).forEach(function(key) {
                  addition_err_msg += '<p>'+err[key]+'</p>';  
                });
              }
              closeLoader();
              showError(validateMobileNo?.data?.message || 'Invalid mobile no. Please try again.', `${addition_err_msg}`);
            }

            if (validateEmail?.data?.status == 1 && validateMobileNo?.data?.status == 1) {
              closeLoader();
              nextStep();
            }
          } catch (error) {
            closeLoader();
            showError('Error. Please try again.');
          }

        }else if (activeStep === 2) {   
            //check if the fields are empty
            if(validateFields(2)){
              return;
            }else{
              nextStep();
            }
            

        }else if (activeStep === 3) {
            //check if the fields are empty
            if(formData.id_number == '' || formData.expiration_date == ''){
                showError('Please fill up all required fields.');
                return;
            }

          showLoader('Sending OTP...')
          // if email not verifed send email
          // if(emailVerifed == 0){
            try {
              const emailresponse = await httpClient.post(links_suffix.SendEmailOtp, {
                  email: formData.email, // Send the email in the request body
              });
              if (emailresponse?.data?.status == 1) {
                 closeLoader();
                 showSuccess('Email sent!');
                 nextStep();
              }else{
                  closeLoader();
                 showError(emailresponse.data?.message || 'Failed to send email!');
              }
              clearOtpField();
            } catch (error) {
              closeLoader();
              showError(error)
            }
          // }else{
          //   closeLoader();
          //   nextStep();
          // }
          

        }else if(activeStep === 4){
          // const otp = `${formData.in1}${formData.in2}${formData.in3}${formData.in4}${formData.in5}${formData.in6}`;
          // const birthDateFormatted = format(new Date(formData.birth_date), 'yyyy-MM-dd');
          // const ExpirationDate = format(new Date(formData.expiration_date), 'yyyy-MM-dd');
          
          // if email not verifed verify email
          // if(emailVerifed == 0){
            showLoader('Verifying OTP...')
            try {
              const verifyEmailOtpRequest = await httpClient.post(links_suffix.VerifyEmailOtp, {
                  email: formData.email,
                  otp_code: otpCode,
              });
              if(verifyEmailOtpRequest.data.status == 1){
                  setEmailVerifed(1);
                  return Swal.fire({
                    title: "Confirm",
                    text: "Are you sure you want to submit your changes?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Submit"
                  }).then((result) => {
                    if (result.isConfirmed) {
                      return handleCreateAccount()
                    }
                  });
    
              }else{
                closeLoader();
                showError(verifyEmailOtpRequest?.data?.message || 'Failed to verify email. Please try again.');
              }
  
            } catch (error) {
              closeLoader();
              showError('Error submitting details! Please try again. ' + error)
            }

          // }else{

          //   Swal.fire({
          //     title: "Confirm",
          //     text: "Are you sure you want to submit your changes?",
          //     icon: "warning",
          //     showCancelButton: true,
          //     confirmButtonColor: "#3085d6",
          //     cancelButtonColor: "#d33",
          //     confirmButtonText: "Submit"
          //   }).then((result) => {
          //     if (result.isConfirmed) {
          //       handleUpdateAccount()
          //     }
          //   });

          // }

        } else {
          closeLoader();
          nextStep();
        }
    };

    const is_success = false;
    const handleCreateAccount = async () => {
        showLoader('Submitting Data...')
        try{
          const formDatas = new FormData();
          formDatas.append('first_name', formData.first_name);
          formDatas.append('middle_name', formData.middle_name);
          formDatas.append('last_name', formData.last_name);
          formDatas.append('email', formData.email);
          formDatas.append('home_office_no', formData.home_office_no);
          formDatas.append('mobile_no', formData.mobile_no);
          formDatas.append('birth_date', formData.birth_date_string);
          formDatas.append('occupation', formData.occupation);
          formDatas.append('present_address', formData.present_address);
          formDatas.append('present_zip_code', formData.present_zip_code);
          formDatas.append('city_prov_state', formData.present_municipality);
          formDatas.append('id_number', formData.id_number);
          formDatas.append('expiration_date', formData.expiration_date_string);
          formDatas.append('file_name', image); 
          formDatas.append('photo_filename', image1);  
          if(checked){
              formDatas.append('promo_email', 1);
          }else{
              formDatas.append('promo_email', 0);
          }
          
          formDatas.append('photo_type', 'user photo');
          formDatas.append('referral_code', formData.referral_code);
          formDatas.append('document_type', formData.document_type);
          const createAccountInformation = await httpClient.post(links_suffix.CreateUserAccount, formDatas, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
          });

          if(createAccountInformation.data.status == 1){

              ReactGA.event({
                  category: 'User',
                  action: 'Successful Sign up'
              });

              closeLoader();
              
              //add to localstorage
              localStorage.setItem('is_success', true);
              
              navigate('/success');
          }else{
              let addition_err_msg = '';
              if(createAccountInformation?.data?.errors){
                let err = createAccountInformation.data.errors;
                Object.keys(err).forEach(function(key) {
                  addition_err_msg += '<p>'+err[key]+'</p>';  
                });
              }
              closeLoader();
              showError(createAccountInformation?.data?.message || 'Create account failed. Please try again.', `${addition_err_msg}`);
          }
        } catch (error) {
          closeLoader();
          showError('Create account failed ' + error)
        }
        
    }

    const createDropdown = (data) => {
      const document_types_list =  (data.length > 0) ? data.map((el) => ({
          value: el.id.toString(),
          label: el.name
        })) : null;

      if(document_types_list){
        setDocumentTypes(document_types_list);
      }
    };

    const handleOnDocumentTypeChange = (value) => {
      if(value){
        const findData = allDocsList.find(
          (docs) => docs.id == value
        );

        if(findData){
          setFormData({
            ...formData,
            document_type: findData.id.toString(),
            document_type_string: findData.name
          });
          setModifiedFields(modifiedFields => [...modifiedFields, "document_type"])
        }
      }
    }

    const fetchDocumentTypes = async () => {
      try{
          // showLoader('Fetching parms...')
          const documentTypesResult = await httpClient.post(links_suffix.GetDocumentTypes, {});

          if(documentTypesResult?.data?.status == 1){
            createDropdown(documentTypesResult?.data?.data)
            setAllDocsList(documentTypesResult?.data?.data)
          }
        } catch (error) {
            closeLoader();
            showError('Fetching signup params error. Please try again.');
        }
    }

    useEffect(()=>{ 
      fetchDocumentTypes()
    },[]);

    const [message, setMessage] = useState('');
    const [ColorMessage, setColorMessage] = useState('');    

    
    const errorIcon = <Icons.AlertCircle size={24} />;
    const successIcon = <Icons.CheckCircle size={24} />;
      
    // Smooth transition duration
    const transitionDuration = 400;
    
    return(
    <div  style={{ 
    backgroundColor: '#ffffff',
    minHeight: 'calc(100vh - 100px)',
    // maxHeight: '100vh',
    fontFamily: 'SalmondRegular',
    fontSize: '32px',
    fontFamily: 'SalmondRegular',}}>
        <Mantine.Center>
            <Mantine.Container style={{
                maxWidth:"100%",
                minWidth:"100%",
            }}
            // px={{xs: 60}}
            p={{base:20, xs: 60, md: 60}}
            // bg ={{base:"yellow", xs: "green", md: "red", lg: "blue"}}
            // p:
            >

                <Mantine.Grid>
                  <Mantine.Grid.Col span={12}>
                      {/* For large screen stepper */}
                      <Stepper
                        active={activeStep + 1}
                        styles={{
                          separator: {
                            border: "5px dotted #270b33",
                            borderTop: "0px",
                            backgroundColor: "#ffffff"
                          },
                          stepIcon:{
                            height: rem(60),
                            width: rem(60),
                            border: "2px solid #270b33",
                          }
                        }}
                        visibleFrom="md"
                      >
                        {steps.map((step, index) => (
                          <Stepper.Step
                            icon={step.icon}
                            label={step.label}
                            color={activeStep >= index ? '#270b33' : 'gray'}
                            completedIcon={step.icon_completed}
                            styles={{
                              step: {display: "flex", flexDirection: "column", gap: "20px"}
                            }}
                          />
                        ))}
                      </Stepper>

                        <Progress value={(activeStep+1) * 20} size="lg" transitionDuration={200} color="#270b33" hiddenFrom="md"/>
                  </Mantine.Grid.Col>
                                  

              <Mantine.Grid.Col span={12}>

                <form onSubmit={handleSubmit} method='enctype= multipart/form-data'>
                <Mantine.Transition
                    mounted={activeStep === 0}
                    transition="fade"
                    duration={transitionDuration}
                    timingFunction="ease">
                    {(styles) => (
                        <div style={styles}>
                            {activeStep === 0 && (
                            <Mantine.Card shadow="md" padding="xl" radius="md" mt={{sm:20, md:70}}>

                                <Title className='header-txt iboldmo1' order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 600,fontFamily:"Salmond", lineHeight:"35.2px"}} fz={{base: 20, xs: 24, sm: 30 ,md:32}} align="start">Personal Information</Title>
                                <Text  className='Au-title'  order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 400 }} fz={{base: 11, sm: 12 ,md:14}} align="start">All fields are required unless marked optional.</Text>
                                <Mantine.Grid>
                                    
                                    <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                        <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>First Name</Text>
                                        <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.first_name} name='first_name' placeholder="First Name" fz={2} 
                                        error={(errorFields.includes('first_name') && !checkForModified('first_name')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                    <Mantine.Grid.Col span={{sm: 12, md: 6}} xs={12}>
                                        <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Middle Name (Optional)</Text> 
                                        <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.middle_name} name="middle_name" placeholder="Middle Name"  
                                        error={(errorFields.includes('middle_name') && !checkForModified('middle_name')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                    <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                        <Text className='iboldmo'  style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Last Name</Text>
                                        <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.last_name} name="last_name"  placeholder="Last Name" 
                                        error={(errorFields.includes('last_name') && !checkForModified('last_name')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                    <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                        <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Birthdate </Text>
                                        <DatePickerInput
                                            className='inputreg'
                                            defaultValue={formData.birth_date}
                                            onChange={(e) => {
                                                if (e) {
                                                  setFormData({...formData, birth_date : e, birth_date_string: format(new Date(e), 'yyyy-MM-dd')}); // Valid date
                                                  setModifiedFields(modifiedFields => [...modifiedFields, "birth_date"])
                                                } else {
                                                  setFormData({ ...formData, birth_date: new Date() }); // Default to today's date
                                                  setModifiedFields(modifiedFields => [...modifiedFields, "birth_date"])
                                                }
                                            }} // Default to today's date if birth_date is not set
                                            value={formData.birth_date || new Date()}
                                            name='birth_date'
                                            valueFormat='YYYY/MM/DD' // Format as "YYYY/MM/DD"
                                            placeholder='Birthdate'
                                            error={(errorFields.includes('birth_date_string') && !checkForModified('birth_date')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                    <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                        <Text  className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Occupation</Text>
                                        <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.occupation} name='occupation'  placeholder="Occupation" 
                                        error={(errorFields.includes('occupation') && !checkForModified('occupation')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                    <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                        <Text  className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Referral Code (Optional)</Text>
                                        <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.referral_code} name='referral_code'  placeholder="Referral Code" 
                                        error={(errorFields.includes('referral_code') && !checkForModified('referral_code')) ? 'This field is required' : ''} />
                                    </Mantine.Grid.Col>

                                          
                                </Mantine.Grid>
                            </Mantine.Card>
                            )}
                        </div>
                    )}
                </Mantine.Transition>
                <Mantine.Transition
                    mounted={activeStep === 1}
                    transition="fade"
                    duration={transitionDuration}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <div style={styles}>
                            {activeStep === 1 && (
                            <Mantine.Card shadow="md" padding="xl" radius="md" mt={{sm:20, md:70}}>
                                <Title className='header-txt iboldmo1' order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 600,fontFamily:"Salmond", lineHeight:"35.2px"}} fz={{base: 20, xs: 24, sm: 30 ,md:32}} align="start">Contact Information</Title>
                                <Text  className='Au-title'  order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 400 }} align="start"  fz={{base: 11, sm: 12 ,md:14}}>All fields are required unless marked optional.</Text>
                                <Mantine.Space h="md" />
                                <Mantine.Grid>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Email</Text>
                                      <Mantine.TextInput className='inputreg' onChange={e => { handleInputChange(e); setEmailVerifed(0)}} value={formData.email} name="email" placeholder="Email" 
                                      error={(errorFields.includes('email') && !checkForModified('email')) ? 'This field is required' : ''} />

                                      <Mantine.Checkbox mt="14px" fz={{base:11, xs:13, sm:14, md:16}} onChange={(event) => {setChecked(event.currentTarget.checked); setModifiedFields(modifiedFields => [...modifiedFields, "promo_email"])}} size="sm" checked = {checked} label="Contact me via email for promos and updates" />
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:"white", marginTop:"20px", fontWeight:600 }} fs="italic" visibleFrom="md">_</Text>
                                      <Mantine.Text style={{marginTop:'20px', fontStyle:"italic"}} fz={{base:13, md:16}}>Email address should be the same as the email to be used for money transfers.</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Mobile No.</Text>
                                      <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.mobile_no}  name="mobile_no" placeholder="Mobile No." 
                                      error={(errorFields.includes('mobile_no') && !checkForModified('mobile_no')) ? 'This field is required' : ''} />
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Work or Home Phone No.</Text>
                                      <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.home_office_no} name="home_office_no" placeholder="Work or Home Phone No."  
                                      error={(errorFields.includes('home_office_no') && !checkForModified('home_office_no')) ? 'This field is required' : ''} />
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Address</Text>
                                      <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.present_address}  name='present_address' placeholder="Address" 
                                      error={(errorFields.includes('present_address') && !checkForModified('present_address')) ? 'This field is required' : ''} />
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>City/Province/State</Text>
                                      <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.present_municipality}  name='present_municipality' placeholder="City/Province/State" 
                                      error={(errorFields.includes('present_municipality') && !checkForModified('present_municipality')) ? 'This field is required' : ''} />
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                      <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Post / Zip  Code</Text>
                                      <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.present_zip_code}  name='present_zip_code' placeholder="Post / ZIP Code" 
                                      error={(errorFields.includes('present_zip_code') && !checkForModified('present_zip_code')) ? 'This field is required' : ''} />
                                  </Mantine.Grid.Col>
                                </Mantine.Grid>
                            </Mantine.Card>
                            )}
                        </div>
                    )}
                </Mantine.Transition>
                <Mantine.Transition
                    mounted={activeStep === 2}
                    transition="fade"
                    duration={transitionDuration}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <div style={styles}>
                            {activeStep === 2 && (
                              
                            <Mantine.Card shadow="md" padding="xl" radius="md" mt={{sm:20, md:70}}>
                                <Title className='header-txt iboldmo1' order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 600,fontFamily:"Salmond", lineHeight:"35.2px"}} fz={{base: 20, xs: 24, sm: 30 ,md:32}} align="start">Verify your Identity</Title>
                                <Text  className='Au-title'  order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 400 }} fz={{base: 11, sm: 12 ,md:14}} align="start">All fields are required unless marked optional.</Text>
                                <Mantine.Space h="md" />
                                <Mantine.Grid>

                                <Mantine.Grid.Col span={{md:4, sm: 12}} xs={12}>
                                  <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>ID Type</Text>
                                  <Mantine.Select
                                    rightSection={<IconChevronDown style={{ width: rem(25), height: rem(30), color: "#323232"}} />}
                                    w='100%'
                                    fw="600"
                                    radius="15px"
                                    fz="2px" 
                                    className='inputreg-select'
                                    // Add classname to specific selector
                                    // classNames={{ option: 'my-rates-select-option', wrapper: 'rates-select'}}
                                    comboboxProps={{ position: 'bottom-start'}}
                                    onChange={handleOnDocumentTypeChange}
                                    data={documentTypes}
                                    value={formData.document_type}
                                    allowDeselect={false}
                                    error={(errorFields.includes('document_type') && !checkForModified('document_type')) ? 'This field is required' : ''}
                                  />
                                </Mantine.Grid.Col>

                                <Mantine.Grid.Col span={{md:4, sm: 12}} xs={12}>
                                    <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>ID No.</Text>
                                    <Mantine.TextInput className='inputreg' onChange={handleInputChange} value={formData.id_number}  name="id_number"  placeholder="ID No." 
                                    error={(errorFields.includes('id_number') && !checkForModified('id_number')) ? 'This field is required' : ''} />
                                </Mantine.Grid.Col>

                                <Mantine.Grid.Col span={{md:4, sm: 12}} xs={12}>
                                    <Text className='iboldmo' style={{color:ForeColorBlack, marginTop:"20px", fontWeight:600 }} fz={{base:13, xs:13, sm:14, md:16}} ta='left'>Expiration Date</Text>
                                    <DatePickerInput
                                            className='inputreg'
                                            onChange={(e) => {
                                                if (e) {
                                                  setFormData({ ...formData, expiration_date: e, expiration_date_string: format(new Date(e), 'yyyy-MM-dd') }); // Valid date
                                                  setModifiedFields(modifiedFields => [...modifiedFields, "expiration_date"])
                                                } else {
                                                  setFormData({ ...formData, expiration_date: new Date() }); // Default to today's date
                                                  setModifiedFields(modifiedFields => [...modifiedFields, "expiration_date"])
                                                }
                                            }}
                                            value={formData.expiration_date || new Date()} // Default to today's date if birth_date is not set
                                            name='expiration_date' 
                                            valueFormat='YYYY/MM/DD' // Format as "YYYY/MM/DD"
                                            placeholder='Select your ID Expiration Date' 
                                            error={(errorFields.includes('expiration_date_string') && !checkForModified('expiration_date')) ? 'This field is required' : ''} />
                                </Mantine.Grid.Col>
                                
                                <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                  <Mantine.Text pt={{base:35,md:50}} pb={{base:18, md:20}} fz={{base:12, xs:14, sm:14, md:16}}>Please make sure that your photos are clear and well-lit. Your face should be shown in full, without any obstructions. (accepted image format (.jpg and .jepg only)</Mantine.Text>
                                </Mantine.Grid.Col>

                                <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12}>
                                  <Mantine.Text pt={50} pb={20} color="white" visibleFrom="md">__</Mantine.Text>
                                </Mantine.Grid.Col>

                                <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12} px={20}>
                                  <Mantine.Grid justify="center" bg="#EEEEEE" py={{base:"80px",md:"80px"}} px={{base:"30px",md:"0px"}} style={{borderRadius:"10px"}}>
                                      <Mantine.Grid.Col className='image-holder' span={{sm:12, md:6}}>
                                            <Mantine.Image radius="md" 
                                             style={{
                                              height: "200px",
                                              width: "100%",
                                            }} 
                                            // w={{base:"auto", xs: "auto", sm: "auto", md:"auto"}} 
                                            fit="contain" src={userDocumentDisplay} fallbackSrc="https://placehold.co/600x400/lightgray/lightgray?text=---" 
                                            />
                                            {userDocumentDisplay  == null && (
                                              <Mantine.FileButton
                                                    className="up-btn"
                                                    onChange={onUserDocumentChange}
                                                    name="filename"
                                                    accept="image/*"
                                                    // capture="user"
                                                    style={{
                                                      position: "absolute",
                                                      top: "50%",
                                                      left: "50%",
                                                      margin:"0px" ,
                                                      transform: "translate(-50%, -50%)"
                                                    }}
                                                    >
                                                    {(props) => (
                                                        <Mantine.Button {...props} className="document-upload"
                                                         p={{base:"0px", md:"10px"}} w={{base:"80%", md: "auto"}} h="50px" bg="#323232" borderRadius="10px" justify="center"  leftIcon={<Icons.Camera />}>
                                                            Upload your ID
                                                        </Mantine.Button>
                                                    )}
                                              </Mantine.FileButton>
                                            )}

                                            {userDocumentDisplay != null && (
                                              <Mantine.FileButton
                                                    className="up-btn1"
                                                    onChange={onUserDocumentChange}
                                                    name="filename"
                                                    accept="image/*"
                                                    // capture="user"
                                                    style={{
                                                      position: "absolute",
                                                      top: "110%",
                                                      left: "50%",
                                                      margin:"0px" ,
                                                      transform: "translate(-50%, -50%)",
                                                    }}
                                                    mt={10}
                                                    >
                                                    {(props) => (
                                                        <Mantine.Button {...props} className="document-upload"
                                                        p={{base:"0px", md:"10px"}} w={{base:"80%", md: "auto"}} h="50px" bg="#323232" borderRadius="10px" justify="center"  leftIcon={<Icons.Camera />}>
                                                            Upload your ID
                                                        </Mantine.Button>
                                                    )}
                                                </Mantine.FileButton>
                                              )}

                                            {errorFields.includes('file_name') && !checkForModified('file_name') && (
                                                <Mantine.Text style={{color:"#fa5252", textAlign:'center'}}>This field is required</Mantine.Text>
                                              )}

                                        </Mantine.Grid.Col >
                                  </Mantine.Grid>
                                </Mantine.Grid.Col>

                                <Mantine.Grid.Col span={{md:6, sm: 12}} xs={12} px={20}>
                                  <Mantine.Grid justify="center" bg="#EEEEEE" py={{base:"80px",md:"80px"}} px={{base:"30px",md:"0px"}} style={{borderRadius:"10px"}}>
                                    <Mantine.Grid.Col className='image-holder' span={{sm:12, md:6}}>
                                            <Mantine.Image radius="md" 
                                               style={{
                                                height: "200px",
                                                width: "100%",
                                              }}
                                              // w={{base:"auto", xs: "auto", sm: "auto", md:"auto"}} 
                                              fit="contain" src={userPhotoDisplay} fallbackSrc="https://placehold.co/600x400/lightgray/lightgray?text=---" />
                                            {userPhotoDisplay == null && (
                                              <Mantine.FileButton
                                                  className="up-btn"
                                                  onChange={onUserPhotoChange}
                                                  name="photo_filename"
                                                  accept="image/*"
                                                  // capture="user"
                                                  style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    margin:"0px" ,
                                                    transform: "translate(-50%, -50%)",
                                                  }}
                                                  >
                                                  {(props) => (
                                                      <Mantine.Button {...props} className="photo-upload"
                                                      // fz={{base:"8px", xs: "8px", sm:"10px", md:"15px"}}
                                                      p={{base:"0px", md:"10px"}} w={{base:"80%", md: "auto"}} h="50px" bg="#323232" borderRadius="10px" justify="center"  leftIcon={<Icons.Camera />}>
                                                        <Mantine.Container w="100%">
                                                          Upload a photo of your face
                                                        </Mantine.Container>
                                                    </Mantine.Button>
                                                  )}
                                                  </Mantine.FileButton>
                                                )}

                                                {userPhotoDisplay != null && (
                                                  <Mantine.FileButton
                                                          className="up-btn1"
                                                          onChange={onUserPhotoChange}
                                                          name="photo_filename"
                                                          accept="image/*"
                                                          // capture="user"
                                                          style={{
                                                            position: "absolute",
                                                            top: "110%",
                                                            left: "50%",
                                                            margin:"0px" ,
                                                            transform: "translate(-50%, -50%)",
                                                          }}
                                                          mt={10}
                                                          >
                                                          {(props) => (
                                                               <Mantine.Button {...props} className="photo-upload"
                                                              //  fz={{base:"6px", xs: "8px", sm:"10px", md:"15px"}}
                                                              p={{base:"0px", md:"10px"}} w={{base:"80%", md: "auto"}} h="50px" bg="#323232" borderRadius="10px" justify="center"  leftIcon={<Icons.Camera />}>
                                                                  <Mantine.Container w="100%">
                                                                      Upload a photo of your face
                                                                  </Mantine.Container>
                                                              </Mantine.Button>
                                                          )}
                                                    </Mantine.FileButton>
                                                )}
                                                
                                                 {errorFields.includes('photo_filename') && !checkForModified('photo_filename') && (
                                                    <Mantine.Text style={{color:"#fa5252", textAlign:'center'}}>This field is required</Mantine.Text>
                                                 )}
                                        </Mantine.Grid.Col>

                                    </Mantine.Grid>                  
                                </Mantine.Grid.Col>
                                </Mantine.Grid>
                            </Mantine.Card>
                            )}
                        </div>
                    )}
                </Mantine.Transition>
                <Mantine.Transition
                    mounted={activeStep === 3}
                    transition="fade"
                    duration={transitionDuration}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <div style={styles}>
                            {activeStep === 3 && (
                            <Mantine.Card shadow="md" padding="xl" radius="md" mt={{sm:20, md:70}}>
                                <Mantine.Title className='header-txt iboldmo1'  order={3} fz={{base: 20, xs: 24, sm: 30 ,md:36}} align="start">Review your Details</Mantine.Title>
                                <Mantine.Text fz={{base: 11, sm: 12 ,md:14}} align="start">All fields are required unless marked optional.</Mantine.Text>
                                <Mantine.Space h="md" />

                                <Mantine.Grid className='review-details-container'>
                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>First Name</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.first_name}</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  
                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Middle Name</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.middle_name}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Last Name</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.last_name}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Birthdate</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.birth_date_string}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Occupation</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.occupation}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Referral Code</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.referral_code}</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  
                                  <Mantine.Grid.Col span={{base:6, md: 3}} mt={20}>
                                    <Mantine.Text className='review-details-label'>Email</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}} mt={20}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.email}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Mobile No.</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.mobile_no}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Work or Home Phone No.</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.home_office_no}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Receive emails?</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{checked == 1 ? 'Yes' : 'No'}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Address</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.present_address}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>City / Province / State</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.present_municipality}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Post / ZIP Code</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.present_zip_code}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}} mt={20}>
                                    <Mantine.Text className='review-details-label'>Document Type</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}} mt={20}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.document_type_string}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>ID No.</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.id_number}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                  <Mantine.Grid.Col span={{base:6, md: 3}}>
                                    <Mantine.Text className='review-details-label'>Expiration Date</Mantine.Text>
                                  </Mantine.Grid.Col>
                                  <Mantine.Grid.Col span={{base:6, md: 9}}>
                                    <Mantine.Text className='review-details-value' fw="800">{formData.expiration_date_string}</Mantine.Text>
                                  </Mantine.Grid.Col>

                                </Mantine.Grid>
                                    
                            </Mantine.Card>
                            )}
                        </div>
                    )}
                </Mantine.Transition>
                <Mantine.Transition
                    mounted={activeStep === 4}
                    transition="fade"
                    duration={transitionDuration}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <div style={styles}>
                            {activeStep === 4 && (
                            <Mantine.Card shadow="md" padding="xl" radius="md" mt={70}>
                                <Title className='Au-title iboldmo1' order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 600,fontFamily:"Salmond", lineHeight:"35.2px"}}  fz={{base: 20, xs: 24, sm: 30 ,md:36}} align="start">Verify your email</Title>
                                <Text  className='Au-title'  order={4} style={{textAlign:'left', color:ForeColorBlack, fontWeight: 400 }} fz={{base: 11, sm: 12 ,md:14}} align="start">Please enter the code we sent to <u>{formData.email}</u></Text>
                                <Mantine.Space h="md" />
                                  {/* {resendEmailStatus == 0 && (
                                    <Mantine.Alert mb={10} variant="light" color="green" radius="md" withCloseButton icon={checkicon}>
                                          <Mantine.Title size="lg">Code has been sent to your email</Mantine.Title>
                                    </Mantine.Alert>
                                  )} 
                                  {resendEmailStatus == 1 && (
                                    <Mantine.Alert mb={10} variant="light" color="green" radius="md" withCloseButton icon={checkicon}>
                                          <Mantine.Title size="lg">New Code has been sent to your email</Mantine.Title>
                                    </Mantine.Alert>
                                  )} */}
                                    <Mantine.Flex  mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap" >

                                        <Box visibleFrom="sm">
                                          <AuthCode
                                            length={6}
                                            allowedCharacters='alphanumeric'
                                            onChange={handleOnCodeChange}
                                            containerClassName='codeInputWrapper'
                                            inputClassName='codeInput'
                                          />
                                        </Box>

                                        <Box hiddenFrom="sm">
                                          <AuthCode
                                            length={6}
                                            allowedCharacters='alphanumeric'
                                            onChange={handleOnCodeChange}
                                            containerClassName='codeInputWrapper'
                                            inputClassName='codeInputMobile'
                                          />
                                        </Box>
                                       
                                        {/* <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in1} name='in1' className='input-code' size="xl" radius="md" />
                                        <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in2} name='in2' className='input-code' size="xl" radius="md" />
                                        <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in3} name='in3' className='input-code' size="xl" radius="md" />
                                        <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in4} name='in4' className='input-code' size="xl" radius="md" />
                                        <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in5} name='in5' className='input-code' size="xl" radius="md" />
                                        <Mantine.TextInput maxLength={1} onChange={handleInputChange} value={formData.in6} name='in6' className='input-code' size="xl" radius="md" /> */}
                                    </Mantine.Flex>


                
                                    <Mantine.Space h="lg" />
                                    <Mantine.Text fz={{base: 11, sm: 12 ,md:14}}> Code will be expired in 5 minutes</Mantine.Text>
                                    <Mantine.Space h="lg" />
                                    <Mantine.Flex  mih={50} gap="md" justify="flex-start" align="flex-start" direction="row" wrap="wrap">
                                    <Mantine.Anchor 
                                    styles={{
                                      root: {
                                        color: "#FFCC05",
                                        textDecoration: "underline",
                                        fontWeight: "700",
                                        fontFamily: "SalmondRegular"
                                      },
                                    }}
                                    fz={{base:"13px", md:"20px"}}
                                    onClickCapture={resendEmail} >Resend Code</Mantine.Anchor>

                                    {resendEmailStatus == 1 && (
                                      <Text style={{fontWeight:450}} fs="italic" fz={{base: 11, sm: 12 ,md:14}} visibleFrom="md">A new code has been sent to your email.</Text>
                                    )} 
                                    </Mantine.Flex>

                               
                            </Mantine.Card>
                            )}
                        </div>
                    )}
                </Mantine.Transition>
                <Mantine.Flex  direction="row" justify={{base:"center", xs:"center", md:"start"}} align="center" gap={10} mt="xl">
              
                <Mantine.Button className='Button-Back' color="#ffcc05"  variant="outline" 
                  h = {{base:"40px", md:"60px"}}
                  w= {{base:"150px", xs:"200px", md:"200px"}}
                  px={{xs: 24, md:24}}
                  fz={{base:"15px", md: "20px"}}
                  py={{base:"10px"}}
                  
                  style={{
                    fontFamily: "Salmond",
                    color: 'black',
                    backgroundColor: 'transparent',
                    border: '2px solid #ffcc05',
                    borderRadius: "50px"
                  }}
                  onClick={prevStep} disabled={activeStep === 0} >
                  <Icons.ArrowLeft className='button-back-arrow'
                  size={40}
                  // style={{marginBottom: '3px', marginRight: '50px'}}
                  />
                    Back
                </Mantine.Button>
                
                {/* Conditionally render "Submit" button for last step */}
                <Mantine.Button color="#FFCC05"
                 h = {{base:"40px", md:"60px"}}
                 w= {{base:"150px", xs:"200px", md:"200px"}}
                 px={{xs: 24, md:24}}
                 fz={{base:"15px", md: "20px"}}
                 py={{base:"10px"}}
                 style={{
                    fontFamily: "SalmondRegular",
                    borderRadius: "50px",
                    color: 'black',
                    border: '2px solid #ffcc05',

                }} type={activeStep === 3? 'submit' : 'button'} onClick={handleSubmit}>
                    {activeStep === 4 ? 'Submit' : 'Next'}
                    {activeStep === 4 ? <Icons.Check className='button-next-arrow' size={33} /> : <Icons.ArrowRight className='button-next-arrow' size={40} />}
                </Mantine.Button>
                </Mantine.Flex>
            </form>
            </Mantine.Grid.Col>
                </Mantine.Grid>
            </Mantine.Container>
        </Mantine.Center>
       



    </div> 

    );
}