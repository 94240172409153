import {Box, Flex, Stack, Title, Text, Image,  Card, Group, Badge, Space, Grid} from '@mantine/core';
import { primaryBgColor,primaryColor, ForeColorPurple, ForeColorYellow } from "../../lib/constants";

function Footer(){

    const mnlxpress = "/img/mnlexpress.png";
    const addressIcon = "/img/footerAddressIcon.png";
    const emailIcon = "/img/footerEmailIcon.png";
    const fbIcon = "/img/footerFBIcon.png";
    const zoomIcon = "/img/footerZoomIcon.png";

    
    return(
        <Box h={{base:'100%', lg: 200}} bg={primaryBgColor} p={50}>
            <Flex align={{base:'left', lg: 'center'}} direction={{base:'column', lg: 'row'}} justify='space-between'>
                <Image src={process.env.PUBLIC_URL + mnlxpress} w={{base:'50%', lg:'100vw'}} maw='100%' h={{base:20, lg:80}} />
                <Flex direction="column" w={{base:'100%', lg: '40vw'}}>
                    <Text mt={{base:'5%', lg: '0%'}} style={{ fontWeight: 700, color: primaryColor, fontSize:'18px' }}>Contact us for your Canada to PH remittance needs!</Text>
                        
                        <Grid mt={20}>
                            <Grid.Col span={{base:12, lg:6}} pt={{base:"0", lg: "2px"}}>
                                <Flex align="center" pt={{base:'6px', lg:'0px'}}>
                                    <Image mr={10} src={process.env.PUBLIC_URL + addressIcon} h={12} w={12}/>
                                    <Text style={{ color: primaryColor, fontSize:'12px'}}>8080 Park Road, Richmond, BC V6Y 1T1</Text>
                                </Flex>

                                <Flex align="center" pt={{base:'6px', lg:'0px'}}>
                                    <Image mr={10} src={process.env.PUBLIC_URL + emailIcon} h={10} w={12}/>
                                    <Text  style={{ color: primaryColor, fontSize:'12px'}}>customerservice@csg.manilaxpress.com</Text>
                                </Flex>
                            </Grid.Col>
                            
                            <Grid.Col span={{base:12, lg:6}} pt={{base:"0", lg: "2px"}} align="right">
                                <Flex align='center'>
                                    <Image mr={10} src={process.env.PUBLIC_URL + zoomIcon} h={7} w='100vw' maw='100%'/>
                                    <Text style={{ color: primaryColor, fontSize:'12px'}}>(236) 200-5859</Text>
                                </Flex>

                                <Flex align="center" pt={{base:'6px', lg:'0px'}}>
                                    <Image mr={10} src={process.env.PUBLIC_URL + fbIcon} h={12} w='100vw' maw='100%'/>
                                    <Text style={{ color: primaryColor, fontSize:'12px'}}>MNLXpress - Canada</Text>
                                </Flex>
                            </Grid.Col>

                        </Grid>
                      
                </Flex>
            </Flex>
        </Box>
    );
}

export default Footer;