import { useState, useEffect } from 'react';
import {Box, Flex, Title, Text, Input, rem, Select, InputBase, Center, useResolvedStylesApi} from '@mantine/core';
import { IconChevronDown, IconHash } from '@tabler/icons-react';
import { ForeColorYellow, ForeColorPurple, primaryColor,  primaryBgColor } from "../../lib/constants";
import xRates from '../../api/x-rates';


const fetchList = async (url, { arg }) => 
  xRates.getList(url, {arg})
  .then((res) => res.data);

function Xrate(){
    const [currentValue, setCurrentValue] = useState({branch_id: "0", php_rate: "0 PHP = 0 USD" , cad_rate: "0 CAD = 0 PHP", date_time_updated: "0000-00-00"});
    const [xrateList, setXrateList] = useState([]);
    const [xrateOptions, setXrateOptions] = useState([]);

    const handeGetList = async () => {
      const response = await fetchList('',[]);

      if(response){
        setXrateList(response);
        createDropdown(response);
        setInitialValue(response[0]);
      }
    };
    
    const createDropdown = (data) => {
      
      const xrate_list =  (data.length > 0) ? data.map((el) => ({
          value: el.id.toString(),
          label: el.branch_name
        })) : null;

      if(xrate_list){
        setXrateOptions(xrate_list);
      }
     
    };

    const handleOnchange = (value) => {
      const branch_id = value;
      
      const findData = xrateList.find(
        (xrate) => xrate.id == branch_id
      );

      if(findData){
        setCurrentValue({
          branch_id: findData.id.toString(),
          php_rate: findData.php_rate,
          cad_rate: findData.cad_rate,
          date_time_updated: findData.date_time_updated
        })
      }
    };

    const setInitialValue = (response) => {
      const initial_branch_id = response?.id;

      if(initial_branch_id){
        setCurrentValue({
          branch_id: response.id.toString(),
          php_rate: response.php_rate,
          cad_rate: response.cad_rate,
          date_time_updated: response.date_time_updated
        })
      }
    };
 
    useEffect(() => {
      handeGetList();
    }, []);

  //   useEffect(() => {
  //     setXrateValue();
  // }, [xrateList]);

    return(
        
        <Box id="Xrate" bg={ForeColorPurple} w='100%' h="100%">
          <Flex direction={{ base: 'column' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', sm: 'center'}} h='100%' w='100%' py={{base: "10%", sm: "5%"}} px={{ base: '15%', xs: '10%', sm: '8%' }} bg={ForeColorPurple}>
            
            <Flex w='100%' justify='center'>
              <Title fw="400" style={{color: ForeColorYellow}} fz={{base: "30px", sm: "36px"}}> Today's Rates </Title>
            </Flex>
            
            <Flex direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }} gap={{ base: 'sm', sm: 'md'}} justify='center' h='100%' w='100%'>
                <Flex w={{ base: '100%', sm: '100%', md: '34%' }} direction="column"  align="left" justify='left'>
                  <Text size='lg' ml='10' fw="700" style={{color: primaryColor}} fz={{base: "15px", sm: "20px"}}>Branch Location</Text>
                  <Select
                    rightSection={<IconChevronDown style={{ width: rem(25), height: rem(30), color: "#323232"}} />}
                    w='100%'
                    mt='10'
                    fw="600"
                    radius="15px"
                    fz="md" 
                    // Add classname to specific selector
                    classNames={{ option: 'my-rates-select-option', wrapper: 'rates-select'}}
                    comboboxProps={{ position: 'bottom-start'}}
                    onChange={handleOnchange}
                    data={xrateOptions}
                    value={currentValue.branch_id}
                    allowDeselect={false}
                  />
                  
                </Flex>
                <Flex w={{ base: '100%', sm: '100%', md: '23%' }} direction="column"  align="center" justify='end'>
                    <Text ta='center' size='lg' mr='10' fw="700" style={{color: primaryColor}} fz={{base: "15px", sm: "20px"}}>CAD = PHP</Text>
                    <Input
                     className='rates-input'
                     w='100%'
                     radius="lg"
                     fw="600"
                     mt='10'
                     readOnly={true}
                     value={currentValue.cad_rate}
                    />
                </Flex>
                <Flex w={{ base: '100%', sm: '100%', md: '23%' }} direction="column"  align="center" justify='end'>
                    <Text ta='center' size='lg' mr='10' fw="700" style={{color: primaryColor}} fz={{base: "15px", sm: "20px"}}> PHP = USD</Text>
                    <Input
                     className='rates-input'
                     w='100%'
                     mt='10'
                     radius="lg"
                     fw="600"
                     readOnly={true}
                     value={currentValue.php_rate}
                    />
                </Flex>
            </Flex>

            <Flex w='100%' justify='center' align='center' direction={{base: "column", sm: "row"}} mt='10'>
              <Text style={{color: primaryColor}} fz={{base: "13px", sm: "16px"}} mr='5'>Last update:</Text>
              <Text style={{color: primaryColor}} fz={{base: "13px", sm: "16px"}} fw="700"> {currentValue.date_time_updated}</Text>
            </Flex>

          </Flex>
        </Box>
    );
}

export default Xrate;